import axios from 'axios';

export default async function fetchOrgReports(orgID, access_token, isRepCo) {
  let orgs = null;
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/scanOrgReport`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        OrgID: orgID,
        isRepCo: isRepCo,
      },
    };
    await axios(params).then((response) => {
      console.log(response);
      orgs = response.data;
    });
  } catch (error) {
    console.log(error.message);
  }
  return orgs;
}
