import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import styles from '../styles/Organization.module.css';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import fetchProfile from 'api/fetchProfile';
import fetchOrgUsers from 'api/fetchOrgUsers';
import { useContext } from 'react';
import { UserContext } from 'auth/UserContext';
import Loading from 'components/Loading';
import { BasicTable } from 'components/BasicTable';
import { FaTrashAlt } from 'react-icons/fa';
import deleteUser from 'api/deleteUser';

const Icon = styled.div`
  svg {
    margin-left: 1rem;
    color: rgb(63, 74, 117);
    &:hover {
      color: #9b7cd7;
    }
  }
`;

const SingleOrg = () => {
  const { userInfo } = useContext(UserContext);
  const { isLoading } = useAuth0();
  const location = useLocation();

  const [org, setOrg] = useState(null);
  const [users, setUsers] = useState(null);
  const [data, setData] = useState([]);

  const [isPageLoading, setLoading] = useState(false);
  const {
    state: { id },
  } = location;

  const updateData = (response) => {
    setData(
      response.map((user) => ({
        userID: user.user_id,
        name: `${user.user_metadata.first_name} ${user.user_metadata.last_name}`,
        title: user.user_metadata.title,
        email: user.email,
        workPhone: user.user_metadata.WorkPhone,
        mobilePhone: user.user_metadata.MobilePhone,
        timeZone: user.user_metadata.TimeZone,
        userRole: user.user_metadata.role === '2' || user.user_metadata.role === '4' ? 'Admin' : 'User',
      }))
    );
  };
  useEffect(() => {
    setLoading(true);
    try {
      const getProfile = async () => {
        console.log(userInfo);
        let accessToken = userInfo.accessToken;
        let response = await fetchProfile(id, accessToken);
        setOrg(response);
        if (!response) {
          response = await fetchProfile(userInfo.user_id, accessToken);
          setOrg(response);
        }
      };
      const getUsers = async () => {
        console.log(userInfo);
        let response = await fetchOrgUsers(id, userInfo.accessToken);
        setUsers(response);

        updateData(response);
      };
      getProfile();
      getUsers();
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSelectedUser = async (userID) => {
    setLoading(true);
    try {
      await deleteUser(userID.replace('auth0|', ''), userInfo.accessToken);

      const response = await fetchOrgUsers(id, userInfo.accessToken);
      updateData(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => [
    {
      Header: '',
      id: 'userList',
      columns: [
        {
          Header: 'Contact Name',
          accessor: 'name',
        },
        {
          Header: 'Contact Title',
          accessor: 'title',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Work Phone',
          accessor: 'workPhone',
        },
        {
          Header: 'Mobile Phone',
          accessor: 'mobilePhone',
        },
        {
          Header: 'Time Zone',
          accessor: 'timeZone',
        },
        {
          Header: 'User Role',
          accessor: 'userRole',
        },
        {
          Header: '',
          accessor: '',
          id: 'delete',
          disableSortBy: true,
          Cell: ({
            row: {
              original: { userID },
            },
          }) => {
            return userInfo.user_metadata.role === '3' || userInfo.user_metadata.role === '5' ? null : (
              <Icon>
                <FaTrashAlt size="18px" data-test-id="delete-user" onClick={() => deleteSelectedUser(userID)} />
              </Icon>
            );
          },
        },
      ],
    },
  ]);

  return (
    <>
      {org && !isLoading ? (
        <>
          <div className={styles.orgListControls}>
            <h1 className="pageTitle">Organization</h1>
            {userInfo.user_metadata.role === '1' ? (
              <div>
                <Link to={'/organizations/addusers'} state={{ id }}>
                  <button className={styles.orgBtn}>Add User</button>
                </Link>
                <Link to={'/organizations/edits'} state={{ id }}>
                  <button className={styles.orgBtn}>Edit Organization</button>
                </Link>
                <Link to="/organizations">
                  <button className={styles.orgBtn}>Back</button>
                </Link>
              </div>
            ) : null}
            {userInfo.user_metadata.role === '2' || userInfo.user_metadata.role === '4' ? (
              <>
                <Link to={'/organizations/addusers'} state={{ id }}>
                  <button className={styles.orgBtn}>Add User</button>
                </Link>
              </>
            ) : null}
          </div>
          <h1 className={styles.usersorgname}>{org.name}</h1>
          {users && !isPageLoading ? (
            <>
              <BasicTable
                columns={columns}
                data={data}
                loadingItems={isLoading}
                initialState={{
                  sortBy: [{ id: 'name', desc: true }],
                }}
              />
              <h1 className={styles.usertotal}>
                {users.length} of {users.length}
              </h1>
            </>
          ) : (
            <Loading />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default withAuthenticationRequired(SingleOrg, {
  onRedirecting: () => <Loading />,
});
