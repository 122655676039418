import * as Yup from 'yup';

const phoneRegExp = /^(\([0-9]{3}\)|([0-9]{3}))[ \\-]*[0-9]{3}[ \\-]*[0-9]{4}?$/;

const ProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'Too Long!').required('First Name is required'),
  lastName: Yup.string().max(50, 'Too Long!').required('Last Name is required'),
  title: Yup.string().max(50, 'Too Long!').required('Title is required'),
  workPhone: Yup.string().matches(phoneRegExp, 'Must be a valid phone number').required('Work Phone is required'),
  mobilePhone: Yup.string().matches(phoneRegExp, 'Must be a valid phone number').required('Mobile Phone is required'),
  timeZone: Yup.string(),
});

const PasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  newPassword2: Yup.string().required('Confirm New Password is required'),
});

//This is a stric npa phone regex, only 10 digits not dash nor anything else allowed
const npaPhoneRegExp = /^((800)|(822)|(833)|(844)|(855)|(866)|(877)|(888))[0-9]{7}?$/;
const phoneSimpleRegExp = /^[0-9]{10}?$/;
const NewReportValidationSchema = Yup.object().shape({
  number: Yup.string()
    .matches(npaPhoneRegExp, 'Must be a valid phone number')
    .required('Callback Toll Free Number is required'),
  date: Yup.date().required('Date of Last Attempted Fraud is required'),
  callerID: Yup.string()
    .matches(phoneSimpleRegExp, 'Must be a valid npa phone number')
    .required('What Number Appeared on the Caller ID is required'),
  state: Yup.string().required('State Where Fraud Occurred is required'),
  impactedOrg: Yup.string(),
  dollarsAsked: Yup.number(),
  comments: Yup.string(),
});

export { ProfileValidationSchema, PasswordValidationSchema, NewReportValidationSchema };
