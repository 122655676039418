import React from 'react';
import styled from 'styled-components';

const ErrorMsg = styled.div`
  color: red;
  margin-left: 56%;
  margin-bottom: -1rem;
`;
function TextError(props) {
  return <ErrorMsg>{props.children}</ErrorMsg>;
}
export default TextError;
export { ErrorMsg };
