import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import styles from '../../styles/Reports.module.css';
import Tabs from 'components/Tabs';
import Loading from 'components/Loading';
import { isRepCoAdmin, isRepCoUser, isAdmin } from 'util/rolesUtil';
import fetchOrgReports from 'api/fetchOrgReports';
import fetchAllReports from 'api/fetchAllReports';
import OpenReportTable from './OpenReportTable';
import ClosedReportTable from './ClosedReportTable';
import { getErrorMessage, MessageBar } from 'components/Message';
import updateReport from 'api/updateReport';
import { Link } from 'react-router-dom';

const { TabNav, TabList, TabContent } = Tabs;

const ReportList = ({ userInfo }) => {
  //   const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [openReports, setOpenReports] = useState([]);
  const [closedReports, setClosedReports] = useState([]);

  let isRepCo;
  if (isRepCoAdmin(userInfo) || isRepCoUser(userInfo)) {
    // isRepCo true means show the reporting entity view
    // isRepCo false means show the respOrg or Somos Admin view
    isRepCo = true;
  } else {
    isRepCo = false;
  }

  const splitReports = (reports) => {
    if (reports) {
      reports.forEach((report) => {
        if (report.report_status === 'open') {
          setOpenReports((preReport) => [...preReport, report]);
        } else {
          setClosedReports((preReport) => [...preReport, report]);
        }
      });
    }
  };

  const fetchReports = () => {
    setLoading(true);
    setMessage({});
    console.log(`Reports useEffect running...`);
    try {
      if (isAdmin(userInfo)) {
        // fetch all reports
        const getReports = async () => {
          let response = await fetchAllReports(userInfo.accessToken);
          splitReports(response);
        };
        getReports();
      } else {
        const getReport = async () => {
          let orgID = userInfo.user_metadata.OrgID;
          let response = await fetchOrgReports(orgID, userInfo.accessToken, isRepCo);
          splitReports(response);
        };
        getReport();
      }
    } catch (e) {
      const msg = getErrorMessage(e);
      setMessage({ type: 'error', msg });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isRepCo]);

  const handleUpdate = async (row) => {
    setLoading(true);
    console.log(row);
    setMessage({});
    try {
      await updateReport(
        userInfo.accessToken,
        row.report_id,
        row.resporg_action,
        row.comments_RespOrg,
        row.retail_CustName,
        row.RepCo_ID
      );
      fetchReports();
      setMessage({ type: 'success', msg: `Report ${row.call_number} successfully created` });
    } catch (e) {
      const msg = getErrorMessage(e);
      setMessage({ type: 'error', msg });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MessageBar type={message.type}>{message.msg}</MessageBar>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to="/reports/addReport">
          <h4 className={styles.newAddButton}>Create Report</h4>
        </Link>
      </div>
      <Tabs defaultValue="tab1">
        <TabList>
          <TabNav value="tab1">Open Reports</TabNav>
          <TabNav value="tab2">Closed Reports</TabNav>
        </TabList>
        <div className="p-4">
          <TabContent value="tab1">
            <OpenReportTable data={openReports} loading={loading} handleUpdate={handleUpdate} />
          </TabContent>
          <TabContent value="tab2">
            <ClosedReportTable data={closedReports} loading={loading} />
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
};

export default withAuthenticationRequired(ReportList, {
  onRedirecting: () => <Loading />,
});
