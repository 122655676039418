import "../styles/LayoutStyles.css";
import PageBody from "./PageBody";
import SiteFooter from "./SiteFooter";
import SiteHeader from "./SiteHeader";

const SharedLayout = () => (
  <>
    <SiteHeader />
    <PageBody />
    <SiteFooter />
  </>
);

export default SharedLayout;
