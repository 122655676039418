import axios from 'axios';

export default async function getPresignedUrl(data, access_token) {
  let urls = null;
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/attachmentsPresignedUrl`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`,
      },
      data,
      //  {
      //   files: [{ reportId: 'todoneedtopassthisin', name: 'testwav.wav' }, { reportId: 'differentreportid', name: 'recording.wav' }],
      // },
    };
    await axios(params).then((response) => {
      console.log(response);
      urls = response.data;
    });
  } catch (error) {
    console.log(error.message);
  }
  return urls;
}
