import * as Yup from 'yup';

const phoneRegExp = /^(\([0-9]{3}\)|([0-9]{3}))[ \\-]*[0-9]{3}[ \\-]*[0-9]{4}?$/;

const SignUpValidationSchema = Yup.object().shape({
  orgName: Yup.string().max(50, 'Too Long!').required('Organization Name is required'),
  contactName: Yup.string().max(50, 'Too Long').required('Contract Full Name is required'),
  contactEmail: Yup.string().email('Must be a valid email').required('Contact Email is required'),
  contactPhone: Yup.string()
    .matches(phoneRegExp, 'Must be a valid phone number')
    .required('Contact phone number is required'),
});
export default SignUpValidationSchema;
