import axios from 'axios';

export default async function signUp(orgName, contactName, contactEmail, contactPhone) {
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/send_email_signupOrg`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer None`, // not used now, for authorization
      },
      data: {
        OrgName: orgName,
        contactName: contactName,
        title: '',
        contactEmail: contactEmail,
        contactPhone: contactPhone,
      },
    };
    await axios(params).then((response) => {
      console.log(response);
    });
  } catch (error) {
    console.log(error.message);
  }
}
