// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FaMinus, FaCheck, FaTimes } from 'react-icons/fa';

const SuccessBackgroundColor = '#dff0d8';
const SuccessBorderColor = '#d0e9c6';
const SuccessColor = '#3c763d';
const ErrorBackgroundColor = '#f2dede';
const ErrorBorderColor = '#ebcccc';
const ErrorColor = '#a94442';

const MessageBody = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 1rem;
  border: 1px solid transparent;

  word-wrap: break-word;
`;

const MessageIcon = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 60px;
  padding: 20px;
  text-align: center;
  color: white;
  background: ${(props) => (props.status === 'success' ? SuccessColor : ErrorColor)};

  > i {
    width: 20px;
    font-size: 20px;
  }
`;

const MessageDiv = styled.div`
  width: 100%;
  display: ${(props) => (props.close ? 'none' : 'table')};
  background-color: ${(props) => (props.status === 'success' ? SuccessBackgroundColor : ErrorBackgroundColor)};
  border-color: ${(props) => (props.status === 'success' ? SuccessBorderColor : ErrorBorderColor)};
  color: ${(props) => (props.status === 'success' ? SuccessColor : ErrorColor)};
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  transition: all 0.5s ease-in-out;
  transform-origin: left top;
  transform: scaleY(1);

  &:target {
    transform: scaleY(0);
  }
`;

const Message = ({ status, close, dataTestId, children }) => (
  <MessageDiv status={status} close={close} data-test-id={dataTestId || 'message'}>
    <MessageIcon status={status}>{status === 'success' ? <FaCheck /> : <FaTimes />}</MessageIcon>
    <MessageBody>{children}</MessageBody>
  </MessageDiv>
);

const MessageMini = styled(MessageIcon)`
  /* position: absolute; */
  display: ${(props) => (props.mini ? 'block' : 'none')};
  right: 0;
  top: 0;
  padding: 20px 5px;
  width: auto;
  transform: translateX(99%);
`;

const CloseButton = styled.button`
  float: right;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

const getErrorMessage = (e) => {
  let msg = '';
  if (e.response && e.response.data) {
    msg = e.response.data.message;

    if (typeof msg === 'string') {
      // do nothing
    } else if (typeof msg === 'object' && msg.errList) {
      msg = msg.errList.map((err) => err.errMsg).join(' , ');
    } else if (typeof msg === 'object' && typeof msg.message === 'string') {
      msg = e.response.data.message.message;
    } else if (msg === undefined && typeof e.response.data.error === 'object') {
      msg = e.response.data.error && e.response.data.error.message;
    } else {
      msg = JSON.stringify(e.response.data);
    }
  } else {
    msg = e.message;
  }
  return msg;
};

const MessageMinimizer = ({ mini }) => (
  <CloseButton onClick={mini}>
    <FaMinus size="12px" />
  </CloseButton>
);

MessageMinimizer.propTypes = {
  mini: PropTypes.func.isRequired,
};

const MessageBar = ({ type, style, dataTestId, children }) => {
  // eslint-disable-next-line
  const [mini, setMini] = useState(false);

  useEffect(() => {
    setMini(false);
  }, [type, children]);

  if (children) {
    return (
      <>
        <Message status={type} style={style} dataTestId={dataTestId} close={mini}>
          {/* <MessageMinimizer mini={() => setMini(true)} /> */}
          {/* <MessageCloser close={() => setClose(true)} /> */}
          <div>{children}</div>
        </Message>
        <MessageMini mini={mini} status={type} onClick={() => setMini(false)}>
          {type === 'success' ? <FaCheck /> : <FaTimes />}
        </MessageMini>
      </>
    );
  }

  return null;
};

export { Message, getErrorMessage, MessageBar };

MessageBar.defaultProps = {
  type: '',
  style: null,
  dataTestId: 'message',
  children: '',
};
MessageBar.propTypes = {
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  dataTestId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
