import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import TextError from './TextError';

const Label = styled.label`
  margin: 1rem;
  font-size: 16px;
  font-weight: 600;

  ${({ required }) =>
    required &&
    `&:after {
      content: ' *';
      color: #e32;
    }`}
`;

const Row = styled.div`
  margin: 2rem;
  select {
    position: relative;
    float: right;
    width: 300px;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
`;

function Select(props) {
  const { label, name, required, options, ...rest } = props;

  return (
    <Row>
      <Label htmlFor={name} required={required}>
        {label}
      </Label>

      <Field as="select" id={name} name={name} {...rest}>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </Row>
  );
}
export default Select;
