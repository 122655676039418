import * as Yup from 'yup';

const domainRegExp = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

const OrganizationValidationSchema = Yup.object().shape(
  {
    orgName: Yup.string().max(50, 'Too Long!').required('Organization Name is required'),
    orgDomain: Yup.string().matches(domainRegExp, 'Must be a valid domain').required('Organization Domain is required'),
    orgNote: Yup.string().max(50, 'Too Long!'),
    orgRole: Yup.mixed().oneOf(['6', '7']).required('Organization Role is required'),
    orgType: Yup.mixed().when(['orgRole'], {
      is: (orgRole) => orgRole === '7',
      then: Yup.mixed()
        .oneOf(['Entertainment', 'Online Retailer', 'Tech Retailer', 'Utility', 'Enforcement', 'Other'])
        .required('Must select one organization type'),
    }),
    respOrgID: Yup.string().when(['orgRole'], {
      is: (orgRole) => orgRole === '6',
      then: Yup.string().max(50, 'Too Long!').required('Resp Org ID is required'),
    }),
  },
  ['orgRole']
);

export default OrganizationValidationSchema;
