import * as Yup from 'yup';

const phoneRegExp = /^(\([0-9]{3}\)|([0-9]{3}))[ \\-]*[0-9]{3}[ \\-]*[0-9]{4}?$/;

const UserValidationSchema = Yup.object().shape(
  {
    firstName: Yup.string().max(50, 'Too Long').required('First Name is required'),
    lastName: Yup.string().max(50, 'Too Long').required('Last Name is required'),
    title: Yup.string().max(50, 'Too Long!').required('Title is required'),
    workPhone: Yup.string().matches(phoneRegExp, 'Must be a valid phone number').required('Work Phone is required'),
    mobilePhone: Yup.string().matches(phoneRegExp, 'Must be a valid phone number').required('Mobile Phone is required'),
    email: Yup.string().email().required('Email is required'),
    userRole: Yup.string()
      .required('Must select one role')
      .when(['orgRole'], {
        is: (orgRole) => orgRole === '6',
        then: Yup.string().oneOf(['2', '3'], 'Must select one resporg role'),
        otherwise: Yup.string().oneOf(['4', '5'], 'Must select one reporting role'),
      }),
  },
  ['orgRole']
);

export default UserValidationSchema;
