import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from 'components/Loading';
import { isRepCoAdmin, isRepCoUser, isAdmin, isRespOrgAdmin, isRespOrgUser } from 'util/rolesUtil';
import AdminReportList from './AdminReportList';
import RepCoReportList from './RepCoReportList';
import ReportList from './ReportList';

function Reports({ userInfo }) {
  console.log(isRepCoAdmin(userInfo));
  return (
    <>
      {isAdmin(userInfo) && <AdminReportList userInfo={userInfo} />}
      {(isRepCoAdmin(userInfo) || isRepCoUser(userInfo)) && <RepCoReportList userInfo={userInfo} />}
      {(isRespOrgAdmin(userInfo) || isRespOrgUser(userInfo)) && <ReportList userInfo={userInfo} />}
    </>
  );
}

export default withAuthenticationRequired(Reports, {
  onRedirecting: () => <Loading />,
});
