import React, { useContext, useState, useEffect, useReducer } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import styles from '../styles/Profile.module.css';
import updateProfile from 'api/updateProfile';
import updatePassword from 'api/updatePassword';
import fetchProfile from 'api/fetchProfile';

import { UserContext } from 'auth/UserContext';
import Loading from 'components/Loading';
import Input from 'components/Input';
import { SubmitButton } from 'components/Button';
import { ProfileValidationSchema } from 'validation/EditProfileValidationSchema';
import { ErrorMsg } from 'components/TextError';
import { getErrorMessage, MessageBar } from 'components/Message';
import { ChangePassword } from 'components/ChangePassword';

const Container = styled.div`
  display: flex;
  padding-top: 1.5rem;

  .formDiv {
    width: 50%;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0 5px;
    border-right: 1px solid black;

    .profileForm,
    .passwordForm {
      padding: 10px 40px 40px 50px;

      label {
        margin-left: 0;
      }
      input {
        width: 100%;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
      }

      ${ErrorMsg} {
        margin-left: 0;
      }
    }
  }

  .passwordForm {
    border: none;
  }
`;

const reducer = (state, action) => {
  switch (action.role) {
    case '1':
      return { roleName: 'Somos Admin' };
    case '2':
      return { roleName: 'Resp Org Admin' };
    case '3':
      return { roleName: 'Resp Org User' };
    case '4':
      return { roleName: 'Reporting Entity Admin' };
    case '5':
      return { roleName: 'Reporting Entity User' };
    default:
      return { roleName: 'undefined' };
  }
};

const EditProfile = () => {
  console.log(`EditProfile FC main called...`);
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [isLoading, setLoading] = useState(true);
  const { getAccessTokenSilently, user } = useAuth0();
  const [profileMsg, setProfileMsg] = useState({});
  const [passwordMsg, setPasswordMsg] = useState({});

  const [state, dispatch] = useReducer(reducer, { role: '0' });

  const getProfile = async () => {
    console.log(`getProfile called...`);
    setLoading(true);
    const access_token = await getAccessTokenSilently();
    const response = await fetchProfile(user.sub, access_token);
    setUserInfo({ ...response, accessToken: access_token });
    setLoading(false);
  };

  useEffect(() => {
    console.log(`useEffect [] called...`);
    if (!userInfo || !userInfo.user_metadata) {
      getProfile();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer;
    if (passwordMsg?.type) {
      timer = setTimeout(() => {
        console.log("useEffect close the password change message");
        setPasswordMsg({});
      }, 5000);
    }
    return () => {
      console.log("cleanup from useEffect password change closer");
      clearTimeout(timer);
    };
  }, [passwordMsg]);

  useEffect(() => {
    console.log(`useEffect [userInfo] called...`);
    dispatch({ role: userInfo.user_metadata.role });
  }, [userInfo]);

  if (Object.keys(userInfo).length === 0) {
    return null;
  }

  const profileInit = {
    firstName: userInfo.user_metadata.first_name,
    lastName: userInfo.user_metadata.last_name,
    title: userInfo.user_metadata.title,
    workPhone: userInfo.user_metadata.WorkPhone,
    mobilePhone: userInfo.user_metadata.MobilePhone,
    timeZone: userInfo.user_metadata.TimeZone,
    roleName: state.roleName,
  };

  const updateHandler = async (values) => {
    console.log(`updateHandler called...`);
    try {
      setLoading(true);

      const user_metadata = {
        MobilePhone: values.mobilePhone,
        TimeZone: values.timeZone,
        WorkPhone: values.workPhone,
        first_name: values.firstName,
        last_name: values.lastName,
        title: values.title,
      };
      await updateProfile(userInfo.user_id, userInfo.accessToken, user_metadata);

      setProfileMsg({ type: 'success', msg: 'Profile updated successfully' });
      await getProfile();
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const updatePassHandler = async () => {
    console.log(`updatePassHandler called...`);
    try {
      setLoading(true);
      await updatePassword(userInfo.accessToken);
      setPasswordMsg({ type: 'success', msg: 'Please check your email for instructions to change your password.' });


    } catch (e) {
      const msg = getErrorMessage(e);
      setPasswordMsg({ type: 'error', msg });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Container>
          <div className="formDiv">
            <MessageBar type={profileMsg.type}>{profileMsg.msg}</MessageBar>
            <div className="profileForm">
              <h1 className={styles.profileupdatetitle}>Profile Information</h1>
              <Formik initialValues={profileInit} validationSchema={ProfileValidationSchema} onSubmit={updateHandler}>
                {(props) => (
                  <Form>
                    <Input label="First Name" name="firstName" />
                    <Input label="Last Name" name="lastName" />
                    <Input label="Role" name="roleName" disabled />
                    <Input label="Title" name="title" />
                    <Input label="Work Phone" name="workPhone" />
                    <Input label="Mobile Phone" name="mobilePhone" />
                    <Input label="Time Zone" name="timeZone" />
                    <div style={{ float: 'right' }}>
                      <SubmitButton type="submit" disabled={!(props.dirty && props.isValid)}>
                        Update
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div className="formDiv" style={{ borderRight: 'none' }}>
            <div className="passwordForm">
              <MessageBar type={passwordMsg.type}>{passwordMsg.msg}</MessageBar>
              <ChangePassword changePwd={updatePassHandler}></ChangePassword>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default withAuthenticationRequired(EditProfile, {
  onRedirecting: () => <Loading />,
});
