import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './util/history';
import './index.css';

const onRedirectCallback = (appState) => {
  history.push(appState && appState.returnTo ? appState.returnTo : `${window.location.origin}/home`);
};

const providerConfig = {
  domain: process.env.REACT_APP_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  audience: process.env.REACT_APP_AUDIENCE,
  redirectUri: window.location.origin,
  useRefreshTokens: true,
  onRedirectCallback,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>
);
