import axios from 'axios';

export default async function updateReport(access_token, report_id, action, comments, retail_CustName, RepCo_ID) {
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/updateReport_RespOrg`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        report_id: report_id,
        action: action,
        comments: comments,
        retail_CustName: retail_CustName,
        RepCo_ID: RepCo_ID,
      },
    };
    await axios(params);
  } catch (error) {
    console.log(error.message);
  }
}
