import { useState } from 'react';
import styles from '../styles/Analytics.module.css';

const RespOrgCard = ({ respOrg }) => {
  const [hover, setHover] = useState(false);

  let respOrgTitle;
  const reportsNumber = `${respOrg.openReports}/${respOrg.totalReports}`;

  let orgIDs;
  // for each respOrg.org_ids (an array), add the trimmed org_id to orgIDs
  if (respOrg.org_ids) {
    orgIDs = respOrg.org_ids.map((org_id) => `${org_id.slice(6, 50)} `);
  }
  if (respOrg.org_ids[0] === 'unknown') {
    orgIDs = 'unknown';
  }

  if (respOrg.org_name !== 'NOT FOUND') {
    // add org name to respOrgTitle
    respOrgTitle = `${respOrg.org_name} (${respOrg.entity_ID.slice(6, 8)}):`;
  } else if (respOrg.entity_ID === 'unknown') {
    respOrgTitle = 'All Unknown Orgs:';
  } else {
    respOrgTitle = `Unnamed (${respOrg.entity_ID.slice(6, 8)}):`;
  }

  return (
    <>
      <div
        className={styles.respOrgListCard}
        onClick={() => {
          setHover(!hover);
        }}
      >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ color: respOrg.color }} className={styles.respOrgListCardTitle}>
            {respOrgTitle}
          </p>
          <p className={styles.respOrgListCardTitle} style={{ marginLeft: '10px', color: respOrg.color }}>
            {reportsNumber}
          </p>
        </div>
        {hover && (
          <>
            <p style={{ fontSize: '13px', paddingTop: '5px' }}>
              <span style={{ fontWeight: 'bold' }}>Avg Days Report Open:</span> {respOrg.averageOpenTimeInDays}
            </p>
            <p style={{ fontSize: '13px' }}>
              <span style={{ fontWeight: 'bold' }}>Most Days To Close Report:</span> {respOrg.longestCloseTimeInDays}
            </p>
            <p style={{ fontSize: '13px', paddingBottom: '5px' }}>
              <span style={{ fontWeight: 'bold' }}>Resporg IDs:</span> {orgIDs}
            </p>
          </>
        )}
      </div>
    </>
  );
};

const RespOrgList = ({ data }) => {
  if (data) {
    return (
      <div style={{ marginTop: '5px' }}>
        {data.map((respOrg) => (
          <RespOrgCard key={respOrg.entity_ID} respOrg={respOrg} />
        ))}
      </div>
    );
  }
};

export default RespOrgList;
