import React, { useState, useEffect } from 'react';
import styles from './DateRangePicker.module.css';

const DateRangePicker = ({ onDateRangeChange, initialStartDate, initialEndDate }) => {
  const [dateRange, setDateRange] = useState({
    start: initialStartDate ? new Date(initialStartDate).toISOString().split('T')[0] : '',
    end: initialEndDate ? new Date(initialEndDate).toISOString().split('T')[0] : '',
  });

  const [dateRangeChanged, setDateRangeChanged] = useState(false);

  useEffect(() => {
    if (dateRangeChanged) {
      const { start, end } = dateRange;
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (!isNaN(startDate) && !isNaN(endDate)) {
        onDateRangeChange(startDate.toISOString(), endDate.toISOString());
        setDateRangeChanged(false);
      }
    }
  }, [dateRange, dateRangeChanged, onDateRangeChange]);

  const handleDateChange = (e, type) => {
    const newDate = e.target.value;
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [type]: newDate,
    }));
    setDateRangeChanged(true);
  };

  const handleResetClick = () => {
    setDateRange({
      start: initialStartDate ? new Date(initialStartDate).toISOString().split('T')[0] : '',
      end: initialEndDate ? new Date(initialEndDate).toISOString().split('T')[0] : '',
    });
    setDateRangeChanged(true);
  };

  // Function to calculate the date range for the last n days
  const setLastDays = (days) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - (days - 1)); // Calculate the start date based on the number of days
    setDateRange({
      start: startDate.toISOString().split('T')[0],
      end: endDate.toISOString().split('T')[0],
    });
    setDateRangeChanged(true);
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <button onClick={() => setLastDays(7)} className={styles.buttonQuickSelect}>
          Last 7 Days
        </button>
        <button onClick={() => setLastDays(14)} className={styles.buttonQuickSelect}>
          Last 14 Days
        </button>
        <button onClick={() => setLastDays(30)} className={styles.buttonQuickSelect}>
          Last 30 Days
        </button>
        <button onClick={() => setLastDays(60)} className={styles.buttonQuickSelect}>
          Last 60 Days
        </button>
        <button onClick={() => setLastDays(90)} className={styles.buttonQuickSelect}>
          Last 90 Days
        </button>
      </div>
      <div>
        <label style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '5px' }}>Start Date:</label>
        <input
          style={{
            padding: '2px 6px',
            border: '1px solid rgb(229, 229, 229)',
            borderRadius: '5px',
            marginRight: '10px',
            cursor: 'pointer',
          }}
          type="date"
          value={dateRange.start}
          onChange={(e) => handleDateChange(e, 'start')}
        />
        <label style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '5px' }}>End Date:</label>
        <input
          style={{
            padding: '2px 6px',
            border: '1px solid rgb(229, 229, 229)',
            borderRadius: '5px',
            marginRight: '10px',
            cursor: 'pointer',
          }}
          type="date"
          value={dateRange.end}
          onChange={(e) => handleDateChange(e, 'end')}
        />
        <button onClick={handleResetClick} className={styles.button}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default DateRangePicker;
