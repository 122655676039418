import React from 'react';
import ReactApexChart from 'react-apexcharts';

function computeData(props) {
  // Current date values used in display calculations
  var today = new Date();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  // An empty array where the indexes represent the 12 months.
  let openReportsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let closedReportsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let TFNDisabled = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let noFraud = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let lableArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  // Loop that tests each report is from within the last year
  // and increments the value at the (month) index of the reportsArray
  props.reports.forEach((report) => {
    let reportYear = parseInt(report.create_date.split('-')[0]);
    let reportMonth = parseInt(report.create_date.split('-')[1]);
    let closedReportYear = parseInt(report.close_date.split('-')[0]);
    let closedReportMonth = parseInt(report.close_date.split('-')[1]);

    if (reportYear === year || (reportMonth > month && reportYear === year - 1)) {
      openReportsArray[reportMonth]++;
    }
    if (report.report_status === 'close') {
      if (closedReportYear === year || (closedReportMonth > month && closedReportYear === year - 1)) {
        closedReportsArray[closedReportMonth]++;
        if (report.resporg_action === 'TFN Disabled') {
          TFNDisabled[closedReportMonth]++;
        }
        if (report.resporg_action === 'No Fraudulent Activity Found') {
          noFraud[closedReportMonth]++;
        }
      }
    }
  });

  // Removes 0 index (no 0th month) from reportsArray
  openReportsArray.shift();
  closedReportsArray.shift();
  TFNDisabled.shift();
  noFraud.shift();
  // Shifts arrays by number of months based on current month
  for (let i = 0; i < month; i++) {
    let first = openReportsArray.shift();
    openReportsArray.push(first);
    first = closedReportsArray.shift();
    closedReportsArray.push(first);
    first = lableArray.shift();
    lableArray.push(first);
    first = TFNDisabled.shift();
    TFNDisabled.push(first);
    first = noFraud.shift();
    noFraud.push(first);
  }

  return {
    lableArray,
    openReportsArray,
    closedReportsArray,
    TFNDisabled,
    noFraud,
  };
}

function Chart(props) {
  let data = computeData(props);
  let state = {
    series: [
      {
        name: 'Reports Filed',
        type: 'line',
        data: data.openReportsArray,
      },
      {
        name: 'Reports Closed',
        type: 'line',
        data: data.closedReportsArray,
      },
    ],
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: [4, 4],
      },
      title: {
        text: 'Total Reports',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: data.lableArray,
      },
    },
  };

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="line"
      height={props.height}
      width={props.width}
    />
  );
}

function BarChart(props) {
  let data = computeData(props);
  let state = {
    series: [
      {
        name: 'TFN Disabled',
        type: 'bar',
        data: data.TFNDisabled,
      },
      {
        name: 'No Fraudulent Activity Found',
        type: 'bar',
        data: data.noFraud,
      },
    ],
    options: {
      chart: {
        stacked: true,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: [0, 0],
      },
      title: {
        text: 'Actions Taken',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: data.lableArray,
      },
    },
  };

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="line"
      height={props.height}
      width={props.width}
    />
  );
}

export { Chart, BarChart };
