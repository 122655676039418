import React, { useState, useContext } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FaEdit } from 'react-icons/fa';
import styled from 'styled-components';
import styles from '../styles/Organization.module.css';
import { Link, useNavigate } from 'react-router-dom';
import fetchAllOrgs from 'api/fetchAllOrgs';
import { UserContext } from 'auth/UserContext';
import Loading from 'components/Loading';
// import { TableWithPaginationStyles } from 'components/TableWithPaginationStyles';
// import { TableWithPagination } from 'components/TableWithPagination';
import OrganizationDataTable from 'components/OragnizationDataTable/OrganizationDataTable';
import TableProvider from 'components/OragnizationDataTable/TableProvider';

const Icon = styled.div`
  svg {
    margin-left: 1rem;
    color: rgb(63, 74, 117);
    &:hover {
      color: #9b7cd7;
    }
  }
`;

function Organizations() {
  return (
    <>
      <Controls />
      <OrgTable />
    </>
  );
}

function Controls() {
  return (
    <>
      <div className={styles.orgListControls}>
        <h1 className="pageTitle">Organizations</h1>
        <Link to="/organizations/add">
          <button className={styles.orgBtn}>Add Organization</button>
        </Link>
      </div>
    </>
  );
}

const PAGESIZE = 50;

function OrgTable() {
  const { userInfo } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async ({ pageIndex, pageSize = PAGESIZE }) => {
    const index = pageIndex ? pageIndex : 0;
    setLoading(true);
    const response = await fetchAllOrgs(userInfo.user_id, userInfo.accessToken, index, pageSize);
    setLoading(false);
    console.log(response);
    return response;
  };

  // const getOrgs = async ({ pageIndex }) => {
  //   console.log('getOrg');
  //   setIsLoading(true);
  //   try {
  //     let response = await fetchAllOrgs(userInfo.user_id, userInfo.accessToken, pageIndex, PAGESIZE);
  //     setOrgs(response);
  //     setTotal(Math.ceil(response.total / PAGESIZE));
  //     setData(
  //       response.users.map((item) => ({
  //         name: item.name,
  //         domain: item.user_metadata.domain,
  //         note: item.user_metadata.note,
  //         role: item.user_metadata.role === '6' ? 'Resp Org' : 'Reporting Entity',
  //         type: item.user_metadata.type,
  //       }))
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getOrgs({
  //     pageIndex: 0,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userInfo]);

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: '',
  //       id: 'organization-table',
  //       columns: [
  //         {
  //           Header: 'Name',
  //           accessor: 'name',
  //         },
  //         {
  //           Header: 'Domain',
  //           accessor: 'domain',
  //           disableSortBy: true,
  //         },
  //         {
  //           Header: 'Note',
  //           accessor: 'note',
  //           disableSortBy: true,
  //         },
  //         {
  //           Header: 'Role',
  //           accessor: 'roleName',
  //           disableSortBy: true,
  //         },
  //         {
  //           Header: 'Type',
  //           accessor: 'type',
  //           disableSortBy: true,
  //         },
  //         {
  //           Header: '',
  //           accessor: '',
  //           id: 'edit',
  //           disableSortBy: true,
  //           Cell: ({
  //             row: {
  //               original: { userId, role },
  //             },
  //           }) => (
  //             <Icon>
  //               <FaEdit
  //                 size="18px"
  //                 data-test-id="edit-org"
  //                 onClick={() => navigate(`/organizations/singleOrg`, { state: { id: userId } })}
  //               />
  //             </Icon>
  //           ),
  //         },
  //       ],
  //     },
  //   ],
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  const columns = React.useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        footer: (props) => props.column.id,
      },
      {
        header: 'Domain',
        accessorKey: 'domain',
        disableSortBy: true,
        footer: (props) => props.column.id,
      },
      {
        header: 'Note',
        accessorKey: 'note',
        disableSortBy: true,
        footer: (props) => props.column.id,
      },
      {
        header: 'Resp Org Entity',
        accessorFn: (row) => {
          if (row.role === 'Resp Org') {
            let userID = `Entity ID: ${row.userId.substring(6, 8)}`;
            return userID;
          } else return '';
        },
        disableSortBy: true,
        cell: ({ row: { original } }) => {
          if (original.role === 'Resp Org') {
            let userID = `Entity ID: ${original.userId.substring(6, 8)}`;
            return userID;
          }
        },
        footer: (props) => props.column.id,
      },
      {
        header: 'Rep Co Category',
        accessorKey: 'type',
        disableSortBy: true,
        footer: (props) => props.column.id,
      },
      {
        header: '',
        accessorKey: '',
        id: 'edit',
        footer: (props) => props.column.id,
        disableSortBy: true,
        cell: ({
          row: {
            original: { userId, role },
          },
        }) => (
          <Icon>
            <FaEdit
              size="18px"
              data-test-id="edit-org"
              onClick={() => navigate(`/organizations/singleOrg`, { state: { id: userId } })}
            />
          </Icon>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {/* <TableWithPaginationStyles>
        <TableWithPagination
          columns={columns}
          fetchData={fetchData}
          isLoading={isLoading}
          initialState={{ sortBy: [{ id: 'name', desc: false }] }}
        />
      </TableWithPaginationStyles> */}
      <TableProvider>
        <OrganizationDataTable fetchData={fetchData} loading={isLoading} columns={columns} />
      </TableProvider>
    </>
  );
}

export default withAuthenticationRequired(Organizations, {
  onRedirecting: () => <Loading />,
});
