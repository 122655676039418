import axios from 'axios';

export default async function getKpiResporg(access_token, data) {
  let value = null;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/kpi/resporg/reports/totals`,
      data, // Pass the data object directly
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    console.log(response);
    value = response; // Access the response data
  } catch (error) {
    console.error('Error:', error);
    return null; // Return null or handle the error as needed
  }
  return value;
}
