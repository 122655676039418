import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import fetchAllReports from 'api/fetchAllReports';
import fetchOrgReports from 'api/fetchOrgReports';
import fetchProfile from 'api/fetchProfile';
import { UserContext } from 'auth/UserContext';
import { Chart, BarChart } from 'components/Chart';
import PieChart from 'components/PieChart';
import RadialChart from 'components/RadialChart';
import { useContext, useEffect, useState } from 'react';
import styles from '../../styles/Analytics.module.css';
import Loading from 'components/Loading';
import getReportsByCategory from 'api/getReportsByCategory';

const MainHomePage = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [reports, setReports] = useState(null);
  const [allReports, setAllReports] = useState(null);
  const [mainChart, setMainChart] = useState(true);

  useEffect(() => {
    if (user && (!userInfo || !userInfo.user_metadata)) {
      const getProfile = async () => {
        const access_token = await getAccessTokenSilently();
        const response = await fetchProfile(user.sub, access_token);
        setUserInfo({ ...response, accessToken: access_token });
      };

      getProfile();
    }

    console.log(userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleMainChart() {
    setMainChart(!mainChart);
  }

  let isRepCo;
  if (userInfo && userInfo.user_metadata) {
    if (userInfo.user_metadata.role >= '4' || userInfo.user_metadata.role === '1') {
      isRepCo = true;
    } else {
      isRepCo = false;
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.user_metadata) {
      if (userInfo.user_metadata.role === '1') {
        const getReports = async () => {
          let response = await fetchAllReports(userInfo.accessToken);
          setReports(response);
          setAllReports(response);
        };
        getReports();
      } else {
        const getReport = async () => {
          let orgID = userInfo.user_metadata.OrgID;
          let response = await fetchOrgReports(orgID, userInfo.accessToken, isRepCo);
          setReports(response);
          response = await getReportsByCategory(userInfo.accessToken, userInfo.user_metadata.Category);
          setAllReports(response);
        };
        getReport();
      }
    }
  }, [userInfo, isRepCo]);

  // Worth of fraudulent activity impacted
  let totalMoney = 0;

  // Open reports, awaiting action
  let openReports = 0;
  let closedReports = 0;

  // Average time closing report
  let totalDaysToClose = 0;
  let averageDaysToClose = 0;
  let totalReportsMarkedFraud = 0;

  // Count of TFNs reported for fraudulent activity
  let allFraudulentNumbersReported = [];

  // Distinct TFNs reported for fraudulent activity
  let uniqueFraudulentNumbersReported = [];

  // Loops over the array of reports and calculates above values
  if (reports) {
    reports.forEach((report) => {
      if (parseInt(report.dollarAsked, 10) >= 0) {
        totalMoney = totalMoney + parseInt(report.dollarAsked, 10);
      }
      if (report.report_status === 'open') {
        openReports = openReports + 1;
      } else {
        totalDaysToClose = totalDaysToClose + (Date.parse(report.close_date) - Date.parse(report.create_date)) / 8.64e7;
        totalReportsMarkedFraud = totalReportsMarkedFraud + 1;
        if (report.resporg_action === 'TFN Disabled') {
          allFraudulentNumbersReported.push(report.call_number);
        }
        closedReports = closedReports + 1;
      }
    });
    if (closedReports > 0) {
      averageDaysToClose = Math.round(totalDaysToClose / closedReports);
    }
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    uniqueFraudulentNumbersReported = allFraudulentNumbersReported.filter(onlyUnique);
  }
  if (reports && allReports !== null) {
    return (
      <>
        <h1 className="pageTitle">Dashboard</h1>
        <section className={styles.homegrid}>
          <div className={styles.statsdiv}>
            <h1>${totalMoney}</h1>
            <p className={styles.statssubtitle}>Worth of fraudulent activity impacted</p>
          </div>
          <div className={styles.statsdiv}>
            <h1>{openReports}</h1>
            <p className={styles.statssubtitle}>Open reports, awaiting action</p>
          </div>
          <div className={styles.statsdiv}>
            <h1>{averageDaysToClose} Days</h1>
            <p className={styles.statssubtitle}>Average time closing report</p>
          </div>
          <div className={styles.statsdiv}>
            <h1>{uniqueFraudulentNumbersReported.length}</h1>
            <p className={styles.statssubtitle}>Distinct TFNs reported for fraudulent activity</p>
          </div>
          <div className={styles.statsdiv}>
            <h1>{allFraudulentNumbersReported.length}</h1>
            <p className={styles.statssubtitle}>Count of TFNs reported for fraudulent activity</p>
          </div>
          {userInfo.user_metadata.role === '1' ? (
            <>
              <div className={styles.div12}>
                <>
                  <h1 className={styles.chartTitle}>Status of Reports</h1>
                  <div className={styles.chartContainer}>
                    <PieChart open={openReports} closed={closedReports} />
                  </div>
                </>
              </div>
            </>
          ) : (
            <>
              <div className={styles.div13}>
                <>
                  <h1 className={styles.chartTitle}>
                    {userInfo.user_metadata.role >= 4
                      ? `Precent Reports in ${userInfo.user_metadata.Category}`
                      : 'Precent Reports Assigned'}
                  </h1>
                  <RadialChart orgReports={reports} totalReports={allReports} />
                </>
              </div>
              <div className={styles.div13}>
                <>
                  <h1 className={styles.chartTitle}>Status of Reports</h1>
                  <div className={styles.chartContainer}>
                    <PieChart open={openReports} closed={closedReports} />
                  </div>
                </>
              </div>
            </>
          )}

          <div className={styles.div14}>
            <button onClick={toggleMainChart} className={styles.toggleBtn}>
              Toggle Graph
            </button>
            {mainChart ? (
              <Chart reports={reports} height={420} width={760} />
            ) : (
              <BarChart reports={reports} height={420} width={760} />
            )}
          </div>
          <div className={styles.div15}>
            {mainChart ? (
              <BarChart reports={reports} height={210} width={500} />
            ) : (
              <Chart reports={reports} height={210} width={500} />
            )}
          </div>
        </section>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default withAuthenticationRequired(MainHomePage, {
  onRedirecting: () => <Loading />,
});
