
/**
 * is the Role a Somos Admin
 * @param {*} userInfo 
 * @returns 
 */
const isAdmin = (userInfo) => {
    const role = userInfo?.user_metadata?.role;
    return role === "1";
};
/**
 * Get the Somos Admin Role
 * @returns 
 */
const getAdminRole = () => {
    return "1";
};
/**
 * is the Role a RespOrg Admin
 * @param {*} userInfo 
 * @returns 
 */
const isRespOrgAdmin = (userInfo) => {
    const role = userInfo?.user_metadata?.role;
    return role === "2";
};
/**
 * get the RespOrg Admin Role
 * @returns 
 */
const getRespOrgAdminRole = () => {
    return "2";
};
/**
 * is the Role a RespOrg User
 * @param {*} userInfo 
 * @returns 
 */
const isRespOrgUser = (userInfo) => {
    const role = userInfo?.user_metadata?.role;
    return role === "3";
};
/**
 * is the Role a Reporting Entity Admin
 * @param {*} userInfo 
 * @returns 
 */
const isRepCoAdmin = (userInfo) => {
    const role = userInfo?.user_metadata?.role;
    return role === "4";
};
/**
 * is the Role a Reporting Entity User
 * @param {*} userInfo 
 * @returns 
 */
const isRepCoUser = (userInfo) => {
    const role = userInfo?.user_metadata?.role;
    return role === "5";
};
const isRespOrgData = (userInfo) => {
    const role = userInfo?.user_metadata?.role;
    return role === "6";
};
const isRepCoData = (userInfo) => {
    const role = userInfo?.user_metadata?.role;
    return role === "7";
};
/**
 * is the Role of any Admin type
 * @param {*} userInfo 
 * @returns 
 */
const isAnyAdminRole = (userInfo) => {
    return (isAdmin(userInfo) || isRespOrgAdmin(userInfo) || isRepCoAdmin(userInfo));
};

export {
    isAdmin,
    getAdminRole,
    isRespOrgAdmin,
    getRespOrgAdminRole,
    isRespOrgUser,
    isRepCoUser,
    isRespOrgData,
    isRepCoData,
    isAnyAdminRole,
    isRepCoAdmin,
};
