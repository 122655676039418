import DateRangePicker from 'components/DateRangePicker/DateRangePicker.jsx';
import styles from './styles/Analytics.module.css';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'auth/UserContext';
import RespOrgList from './components/RespOrgList';
import AssignedReportsPieChart from './components/AssignedReportsPieChart';
import TopFiveTables from './components/TopFiveTables';
import ReportsByYearTable from './components/ReportsByYearTable';
import getKpiResporg from 'api/getKpiResporg';

const RespOrgAnalytics = () => {
  const { userInfo } = useContext(UserContext);
  // Define initial start and end dates as constants (immutable)
  const initialStartDate = new Date('2020-01-01T00:00:00.000Z');
  const initialEndDate = new Date();
  const [kpiData, setKpiData] = useState([]);
  const [showPiePrecents, setShowPiePrecents] = useState(true);

  // State to store the selected date range
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  useEffect(() => {
    const { startDate, endDate } = selectedDateRange;

    if (!startDate || !endDate) {
      return;
    }

    const getKpiData = async () => {
      const result = await getKpiResporg(userInfo.accessToken, selectedDateRange);

      // Sort the data by open reports but keep entity_ID = unknown at the top
      result.data.sort((a, b) => {
        if (a.entity_ID === 'unknown') {
          return -1;
        } else if (b.entity_ID === 'unknown') {
          return 1;
        } else {
          return b.openReports - a.openReports;
        }
      });

      // Add colors to data
      const colors = [
        '#dc3912',
        '#3366cc',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
        '#316395',
        '#3366cc',
        '#994499',
        '#22aa99',
        '#aaaa11',
        '#6633cc',
        '#e67300',
        '#8b0707',
        '#651067',
        '#329262',
        '#5574a6',
        '#3b3eac',
        '#b77322',
        '#16d620',
        '#b91383',
        '#f4359e',
        '#9c5935',
        '#a9c413',
        '#2a778d',
        '#668d1c',
        '#bea413',
        '#0c5922',
        '#743411',
      ];

      result.data.forEach((item, index) => {
        item.color = colors[index % colors.length];
      });

      setKpiData(result.data);
    };
    getKpiData();
  }, [selectedDateRange, userInfo]);

  // Function to handle date range selection
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate });
  };
  return (
    <>
      <div className={styles.dateRangeDiv}>
        <DateRangePicker
          initialStartDate={initialStartDate}
          initialEndDate={initialEndDate}
          onDateRangeChange={handleDateRangeChange}
        />
      </div>
      <section className={styles.homegrid}>
        <div className={styles.gridDiv1}>
          <h4 className={styles.blockTitle}>Resp Org List: (Open / Total)</h4>
          <div className={styles.innerDiv1}>
            <RespOrgList data={kpiData} />
          </div>
        </div>
        <div className={styles.gridDiv2}>
          <h4 className={styles.blockTitle}>Reports Assigned To Resp Orgs</h4>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              onClick={() => {
                setShowPiePrecents(!showPiePrecents);
              }}
              className={styles.piePrecentBtn}
            >
              {showPiePrecents ? 'Hide Percentage' : 'Show Percentage'}
            </button>
          </div>
          <div className={styles.innerDiv2}>
            <AssignedReportsPieChart data={kpiData} precents={showPiePrecents} />
          </div>
        </div>
        <div className={styles.gridDiv3}>
          <h4 className={styles.blockTitle}>Top 5 Resp Orgs With The Highest Number Of Open Reports</h4>
          <div>
            <TopFiveTables data={kpiData} chartType={1} />
          </div>
        </div>
        <div className={styles.gridDiv3}>
          <h4 className={styles.blockTitle}>Top 5 Resp Orgs Having Reports Pending For Longest Time (Days)</h4>
          <div>
            <TopFiveTables data={kpiData} chartType={2} />
          </div>
        </div>
        <div className={styles.gridDiv2}>
          <h4 className={styles.blockTitle}>Reports Assigned To Resp Orgs By Year</h4>
          <div className={styles.innerDiv1}>
            <ReportsByYearTable data={kpiData} />
          </div>
        </div>
        <div className={styles.gridDiv3}>
          <h4 className={styles.blockTitle}>Top 5 Resp Orgs With Longest Time Taken To Close Report (Days)</h4>
          <TopFiveTables data={kpiData} chartType={3} />
        </div>
      </section>
    </>
  );
};

export default RespOrgAnalytics;
