import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import LoggedInMenu from './LoggedInMenu';

const SiteHeader = () => {
  const { logout, isAuthenticated } = useAuth0();

  const frsLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <header className="site-header">
      <div className="site-header-div">
        <Link to="/home">
          <img
            className="header-logo"
            src={require('../images/Logo.png')}
            alt="Fraud Reporting Services (project title) Logo"
          />
        </Link>
        <nav className="header-nav">
          {isAuthenticated ? (
            <>
              <LoggedInMenu />
              <div className="header-user">
                <div className="dropdown">
                  <button className="dropbtn">
                    <Link to="/profile">
                      <FaUser />
                    </Link>
                  </button>
                  <div className="dropdown-content">
                    {/* <li className="header-li">
                      <Link to="/profile">My Profile</Link>
                    </li> */}
                    <li className="header-li">
                      <Link to="/updateProfile">Edit Profile</Link>
                    </li>
                    <li className="header-li" onClick={frsLogout}>
                      <button className="header-logout-btn">Logout</button>
                    </li>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <Link to="/signup">
                <button className="signUp-button">Sign Up</button>
              </Link>
              <LoginButton /> */}
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default SiteHeader;
