/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import Loading from 'components/Loading';
import styled from 'styled-components';
import { FaSortDown, FaSortUp, FaSort } from 'react-icons/fa';

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  font-size: 1rem;
  // height: 50%;

  svg {
    vertical-align: middle;
    margin-left: 0.3rem;
  }
  thead tr {
    :first-child {
      display: none;
    }
  }
  thead th {
    font-family: SharpSansBold, sans-serif;
    background-color: white;
    color: #777;
    border-bottom: 2px solid #9b7cd7;
    border-top: 2px solid #9b7cd7;
    white-space: nowrap;
  }

  th,
  td {
    margin: 0.5rem;
    padding: 0.275rem;
    text-align: center;
    border-bottom: 1px solid #ddd;
    height: 40px;
    :last-child {
      border-right: 0;
    }

    button {
      width: 120px;
    }
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
// eslint-disable-next-line react/prop-types
function BasicTable({ columns, data, loadingItems, initialState = {} }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy
  );

  const getColumnSortIcon = ({ isSorted, isSortedDesc, disableSortBy }) => {
    // by default all the column of table can be sorted alphnumerical.
    // to disable the sorting, set disableSortBy: true, in the column setting

    if (disableSortBy) {
      return '';
    }

    if (isSorted) {
      if (isSortedDesc) {
        return <FaSortDown />;
      }

      return <FaSortUp />;
    }
    return <FaSort />;
  };
  // Render the UI for your table
  return (
    <>
      {loadingItems && <Loading />}
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>{getColumnSortIcon(column)}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} data-test-id={`row-${i}`}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} data-test-id={`row-${i}-${cell.column.id}`}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export { BasicTable };
