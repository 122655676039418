import DataTable from 'components/DataTable';
import React, { useMemo } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';

const ClosedReportTable = ({ data, loading }) => {
  // for each row, if there is a respOrgDetails, use the org_name, otherwise use the RespOrg_ID
  data.forEach((item) => {
    const org = item.respOrgDetails?.org_name || `Unassigned: ${item.RespOrg_ID.substring(6, 12)}`;
    item.assignedOrgName = org;

    // fix unknown orgs
    if (item.assignedOrgName === 'Unassigned: n') {
      item.assignedOrgName = 'Unknown';
    }
  });

  const columns = useMemo(
    () => [
      { header: 'Name of assigned Resp Org', accessorKey: 'assignedOrgName', footer: (props) => props.column.id },
      { header: 'Investigation Outcome', accessorKey: 'resporg_action', footer: (props) => props.column.id },
      { header: 'Resp Org Comments', accessorKey: 'comments_RespOrg', footer: (props) => props.column.id },
      { header: 'Retail Customer Name', accessorKey: 'retail_CustName', footer: (props) => props.column.id },
      {
        header: 'Date Created',
        type: 'date',
        accessorKey: 'create_date',
        footer: (props) => props.column.id,
        cell: ({
          row: {
            original: { create_date },
          },
        }) => {
          const d = new Date(create_date);
          return <span>{d.toISOString().split('T')[0]}</span>;
        },
      },
      {
        header: 'Date CLosed',
        type: 'date',
        accessorKey: 'close_date',
        footer: (props) => props.column.id,
        cell: ({
          row: {
            original: { create_date },
          },
        }) => {
          const d = new Date(create_date);
          return <span>{d.toISOString().split('T')[0]}</span>;
        },
      },
      { header: 'Callback Toll Free Number', accessorKey: 'call_number', footer: (props) => props.column.id },
      {
        header: 'Date of last attempted fraud',
        type: 'date',
        accessorKey: 'fraud_date',
        footer: (props) => props.column.id,
      },
      { header: 'What number appeared on the caller ID', accessorKey: 'caller_id', footer: (props) => props.column.id },
      {
        header: 'State Where Fraud Occurred',
        accessorKey: 'occurrence_state',
        footer: (props) => props.column.id,
      },
      {
        header: 'Impacted Organization',
        accessorKey: 'impactOrg',
        footer: (props) => props.column.id,
      },
      {
        header: 'Amount Asked For or Paid by the Victim',
        accessorKey: 'dollarAsked',
        cell: ({
          row: {
            original: { dollarAsked },
          },
        }) => {
          let line = `$${Number(dollarAsked)} USD`;
          return <span>{line}</span>;
        },
        footer: (props) => props.column.id,
      },
      {
        header: 'Additional Information',
        accessorKey: 'comments_RepCo',
        footer: (props) => props.column.id,
      },
      {
        header: 'Supporting Files',
        accessorFn: (row) => row.attachmentsDetails?.length || 0,
        footer: (props) => props.column.id,
        cell: ({ row: { original } }) => {
          const { attachmentsDetails } = original;
          if (attachmentsDetails && attachmentsDetails.length > 0) {
            // console.log(attachmentsDetails);
            return (
              <div className="flex flex-row justify-between">
                {attachmentsDetails.map((item) => {
                  return (
                    <a
                      key={item.name}
                      title={item.name}
                      href={item.url}
                      download={item.name}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <AiOutlineFileText />
                    </a>
                  );
                })}
              </div>
            );
          }
        },
      },
    ],
    []
  );

  return (
    <DataTable data={data} loading={loading} columns={columns} toCSV={{ display: true, name: 'closed_reports.csv' }} />
  );
};

export default ClosedReportTable;
