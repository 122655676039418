import axios from 'axios';

export default async function fetchAllReports(access_token) {
  let orgs = null;
  try {
    var params = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/scanAllReport`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
    };
    await axios(params).then((response) => {
      console.log(response);
      orgs = response.data;
    });
  } catch (error) {
    console.log(error.message);
  }
  return orgs;
}
