import Tabs from './Tabs';
import TabList from './TabList';
import TabNav from './TabNav';
import TabContent from './TabContent';

Tabs.TabList = TabList;
Tabs.TabNav = TabNav;
Tabs.TabContent = TabContent;

export default Tabs;
