import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

const MessageBar = styled.div`
  width: 100%;
  height: 200px;
  background-color: rgb(63, 74, 117);
  background-image: radial-gradient(circle, rgb(63, 74, 117) 0%, rgb(28, 36, 70) 89%);
`;

const TitleDiv = styled.div`
  margin: auto;
  width: 80%;
  padding-top: 5rem;
`;
const Title = styled.h1`
  color: white;
  font-family: SharpSansSemiBold, sans-serif;
  font-size: 1.7rem;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  width: 80%;
  margin-top: -3rem;
  box-shadow: rgb(0 0 0 / 10%) 2px 2px 16px 0px;
  z-index: 1 !important;
  background: white;
`;
const LeftContainer = styled.div`
  height: 60vh;
  float: left;
`;

const LoginDiv = styled.div`
  width: 80%;
  margin: auto;
  font-family: SharpSansSemiBold, sans-serif;
  padding-top: 5rem;
`;

const ImgDiv = styled.div`
  background: rgba(63, 74, 117) url('/frs-report.png') no-repeat right;
  background-size: 100%;
`;

const MsgStyle = styled.p`
  font-family: SharpSansSemiBold, sans-serif;
  font-size: 0.8rem;
  color: grey;
  margin: 1rem 0;
`;

const Button = styled.button`
  background: #3e4c78;
  color: white;
  border: 1px solid #3e4c78;
  border-radius: 3px;
  width: 60%;
  height: 30px;
  margin: 5rem auto 0.5rem 20%;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: #3e4c78;
  }
`;

const SignUpButton = styled.button`
  margin: 0.5rem auto auto 20%;
  background: white;
  color: #3e4c78;
  border: 1px solid #3e4c78;
  border-radius: 3px;
  width: 60%;
  height: 30px;
  cursor: pointer;

  &:hover {
    background-color: #3e4c78;
    color: white;
  }
`;

const HrText = styled.hr`
  line-height: 3.5em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 3.5em;
  opacity: 0.5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
`;

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  const handler = async () => {
    console.log(isAuthenticated);
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    } else {
      await loginWithRedirect({
        redirect_uri: `${window.location.origin}/organizations`,
      });
    }
  };

  return <Button onClick={handler}>Log In</Button>;
};

const Login = () => {
  return (
    <>
      <MessageBar>
        <TitleDiv>
          <Title>Welcome to Fraud Report Service!</Title>
        </TitleDiv>
      </MessageBar>
      <GridContainer>
        <LeftContainer>
          <LoginDiv>
            <h3>Log In</h3>
            <MsgStyle>Login with your FRS info you received/created during your registration</MsgStyle>
            <LoginButton />
            <HrText data-content="OR" />
            <Link to="/signup">
              <SignUpButton>Sign Up</SignUpButton>
            </Link>
          </LoginDiv>
        </LeftContainer>
        <ImgDiv />
      </GridContainer>
    </>
  );
};

export default Login;
