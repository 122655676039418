import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts';

const TopFiveTables = ({ data, chartType }) => {
  // Copy the data array and remove the unknown entity_ID
  let localData = [...data];
  localData = localData.filter((item) => item.entity_ID !== 'unknown');

  let topFiveOpenReports;
  let topFiveAvgDaysOpen;
  let topFiveClosedReports;

  // Top 5 open reports by entity_ID to topFiveOpenReports
  topFiveOpenReports = localData
    .sort((a, b) => b.openReports - a.openReports)
    .slice(0, 5)
    .map((item) => {
      return {
        name: item.entity_ID.slice(6, 8),
        open: item.openReports,
        color: item.color,
      };
    });

  // Top 5 avg days open by entity_ID to topFiveAvgDaysOpen
  topFiveAvgDaysOpen = localData
    .sort((a, b) => b.averageOpenTimeInDays - a.averageOpenTimeInDays)
    .slice(0, 5)
    .map((item) => {
      return {
        name: item.entity_ID.slice(6, 8),
        pending: item.averageOpenTimeInDays,
        color: item.color,
      };
    });

  // Top 5 closed reports by entity_ID to topFiveClosedReports
  topFiveClosedReports = localData
    .sort((a, b) => b.longestCloseTimeInDays - a.longestCloseTimeInDays)
    .slice(0, 5)
    .map((item) => {
      return {
        name: item.entity_ID.slice(6, 8),
        closed: item.longestCloseTimeInDays,
        color: item.color,
      };
    });

  return (
    <>
      {chartType === 1 && <HorizontalBarChart1 data={topFiveOpenReports} />}
      {chartType === 2 && <HorizontalBarChart2 data={topFiveAvgDaysOpen} />}
      {chartType === 3 && <HorizontalBarChart3 data={topFiveClosedReports} />}
    </>
  );
};

const HorizontalBarChart1 = ({ data }) => {
  return (
    <div style={{ position: 'relative', left: '-20px', top: '8px' }}>
      <BarChart width={560} height={235} data={data} layout="vertical">
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="open">
          {data.map((d, idx) => (
            <Cell key={`cell-${idx}`} fill={d.color} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

const HorizontalBarChart2 = ({ data }) => {
  return (
    <div style={{ position: 'relative', left: '-20px', top: '8px' }}>
      <BarChart width={560} height={235} data={data} layout="vertical">
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="pending">
          {data.map((d, idx) => (
            <Cell key={`cell-${idx}`} fill={d.color} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

const HorizontalBarChart3 = ({ data }) => {
  return (
    <div style={{ position: 'relative', left: '-20px', top: '8px' }}>
      <BarChart width={560} height={235} data={data} layout="vertical">
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="closed">
          {data.map((d, idx) => (
            <Cell key={`cell-${idx}`} fill={d.color} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

export default TopFiveTables;
