import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import TextError from './TextError';

const Label = styled.label`
  margin: 1rem;
  font-size: 16px;
  font-weight: 600;
  width: 300px;

  ${({ required }) =>
    required &&
    `&:after {
      content: ' *';
      color: #e32;
    }`}
`;

const Row = styled.div`
  margin: 2rem;
`;

const StyledField = styled(Field)`
  position: relative;
  float: right;
  width: 300px;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #fff;

  &:disabled {
    background: #ddd;
  }
`;

function Input(props) {
  const { label, required, name, ...rest } = props;
  return (
    <Row>
      <Label htmlFor={name} required={required}>
        {label}
      </Label>
      <StyledField id={name} name={name} {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </Row>
  );
}
export default Input;
export { Label };
