import { withAuthenticationRequired } from '@auth0/auth0-react';

import fetchOrgReports from 'api/fetchOrgReports';
import { Chart, BarChart } from 'components/Chart';
import PieChart from 'components/PieChart';
import RadialChart from 'components/RadialChart';
import { useEffect, useState } from 'react';
import styles from '../../styles/RespOrgHome.module.css';
import Loading from 'components/Loading';
import getReportsByCategory from 'api/getReportsByCategory';
import { useNavigate } from 'react-router-dom';

const RespOrgHomePage = ({ userInfo }) => {
  // To navigate with div click
  const navigate = useNavigate();

  // Record of all reports unfiltered
  const [allReportsUnfiltered, setAllReportsUnfiltered] = useState(null);
  // Date filtered reports used by logic
  const [reports, setReports] = useState(null);
  // Int of total number of reports in this resp org's category
  const [allReports, setAllReports] = useState(null);
  const [mainChart, setMainChart] = useState(true);

  // For date range selection
  const [startDate, setStartDate] = useState(`2021-01-01`);
  // Current date as yyyy-mm-dd string
  const [endDate, setEndDate] = useState(getCurrentDateString());

  function toggleMainChart() {
    setMainChart(!mainChart);
  }

  function getCurrentDateString() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`;
  }

  function dateFilter(startSelection, endSelection) {
    let start = null;
    let end = null;

    // If user changed the start date
    if (startSelection) {
      start = startSelection;
      end = endDate;
      setStartDate(startSelection);
    }
    // If user changed the end date
    if (endSelection) {
      end = endSelection;
      start = startDate;
      setEndDate(endSelection);
    }

    // If user cleared the start date
    if (end === null) {
      end = getCurrentDateString();
      setEndDate(end);
    }

    // If user cleared the end date
    if (start === null) {
      start = '2021-01-01';
      setStartDate(start);
    }

    if (start && end) {
      const filteredReports = [];
      allReportsUnfiltered.forEach((report) => {
        if (
          Date.parse(report.create_date) >= Date.parse(start) &&
          Date.parse(report.create_date) <= Date.parse(end + 'T23:59:59')
        ) {
          filteredReports.push(report);
        }
      });
      setReports(filteredReports);
    } else {
      setReports(allReportsUnfiltered);
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.user_metadata) {
      const getReport = async () => {
        const orgID = userInfo.user_metadata.OrgID;
        const isRepCo = false;
        let response = await fetchOrgReports(orgID, userInfo.accessToken, isRepCo);
        setReports(response);
        setAllReportsUnfiltered(response);
        response = await getReportsByCategory(userInfo.accessToken, userInfo.user_metadata.Category);
        setAllReports(response);
      };
      getReport();
    }
  }, [userInfo]);

  // Worth of fraudulent activity impacted
  let totalMoney = 0;

  // Open reports, awaiting action
  let openReports = 0;
  let closedReports = 0;

  // Reports pending for more than 5 days
  let fiveDaysPending = 0;

  // Average time closing report
  let totalDaysToClose = 0;
  let averageDaysToClose = 0;
  let totalReportsMarkedFraud = 0;

  // Count of TFNs reported for fraudulent activity
  let allFraudulentNumbersReported = [];

  // Distinct TFNs reported for fraudulent activity
  let uniqueFraudulentNumbersReported = [];

  // Loops over the array of reports and calculates above values
  if (reports) {
    reports.forEach((report) => {
      if (parseInt(report.dollarAsked, 10) >= 0) {
        totalMoney = totalMoney + parseInt(report.dollarAsked, 10);
      }
      if (report.report_status === 'open') {
        openReports = openReports + 1;
        if (Date.now() - Date.parse(report.create_date) > 4.32e8) {
          fiveDaysPending = fiveDaysPending + 1;
        }
      } else {
        totalDaysToClose = totalDaysToClose + (Date.parse(report.close_date) - Date.parse(report.create_date)) / 8.64e7;
        totalReportsMarkedFraud = totalReportsMarkedFraud + 1;
        if (report.resporg_action === 'TFN Disabled') {
          allFraudulentNumbersReported.push(report.call_number);
        }
        closedReports = closedReports + 1;
      }
    });
    if (closedReports > 0) {
      averageDaysToClose = Math.round(totalDaysToClose / closedReports);
    }
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    uniqueFraudulentNumbersReported = allFraudulentNumbersReported.filter(onlyUnique);
  }
  if (reports && allReports !== null) {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h1 className="pageTitle">Dashboard</h1>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p className={styles.dateRangeTitle}>Date range:</p>
            <input
              type="date"
              className={styles.dateInput}
              onChange={(e) => {
                dateFilter(e.target.value, null);
              }}
            />
            <input
              type="date"
              className={styles.dateInput}
              onChange={(e) => {
                dateFilter(null, e.target.value);
              }}
            />
          </div>
        </div>
        <section className={styles.homegrid}>
          <div className={styles.statsdiv}>
            <h1>${totalMoney}</h1>
            <p className={styles.statssubtitle}>Worth of fraudulent activity reported</p>
          </div>
          <div
            className={styles.statsdivclickable}
            onClick={() => {
              navigate('/reports');
            }}
          >
            <h1>{openReports}</h1>
            <p className={styles.statssubtitle}>Open reports, awaiting action</p>
          </div>
          <div
            className={styles.statsdivclickable}
            onClick={() => {
              navigate('/reports');
            }}
          >
            <h1>{fiveDaysPending}</h1>
            <p className={styles.statssubtitle}>Reports pending for more than 5 days</p>
          </div>
          <div className={styles.statsdiv}>
            <h1>{averageDaysToClose} Days</h1>
            <p className={styles.statssubtitle}>Average time closing report</p>
          </div>
          <div className={styles.statsdiv}>
            <h1>{uniqueFraudulentNumbersReported.length}</h1>
            <p className={styles.statssubtitle}>Distinct TFNs reported for fraudulent activity</p>
          </div>
          <div className={styles.statsdiv}>
            <h1>{allFraudulentNumbersReported.length}</h1>
            <p className={styles.statssubtitle}>Count of TFNs reported for fraudulent activity</p>
          </div>
          {userInfo.user_metadata.role === '1' ? (
            <>
              <div className={styles.div12}>
                <>
                  <h1 className={styles.chartTitle}>Status of Reports</h1>
                  <div className={styles.chartContainer}>
                    <PieChart open={openReports} closed={closedReports} />
                  </div>
                </>
              </div>
            </>
          ) : (
            <>
              <div className={styles.div13}>
                <>
                  <h1 className={styles.chartTitle}>
                    {userInfo.user_metadata.role >= 4 ? (
                      `Precent Reports in ${userInfo.user_metadata.Category}`
                    ) : (
                      <>
                        <p className={styles.shareOfReports}>Share of Total Reports</p>{' '}
                        <p className={styles.shareOfReportsBottom}>(Your Organization Vs. Other Resp Orgs)</p>
                      </>
                    )}
                  </h1>
                  <RadialChart orgReports={reports} totalReports={allReports} />
                </>
              </div>
              <div className={styles.div13}>
                <>
                  <h1 className={styles.chartTitle}>Status of Reports</h1>
                  <div className={styles.chartContainer}>
                    <PieChart open={openReports} closed={closedReports} />
                  </div>
                </>
              </div>
            </>
          )}
          <div className={styles.div14}>
            <button onClick={toggleMainChart} className={styles.toggleBtn}>
              Toggle Graph
            </button>
            {mainChart ? (
              <Chart reports={reports} height={420} width={840} />
            ) : (
              <BarChart reports={reports} height={420} width={840} />
            )}
          </div>
          <div className={styles.div15}>
            {mainChart ? (
              <BarChart reports={reports} height={210} width={500} />
            ) : (
              <Chart reports={reports} height={210} width={500} />
            )}
          </div>
        </section>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default withAuthenticationRequired(RespOrgHomePage, {
  onRedirecting: () => <Loading />,
});
