import axios from 'axios';
import { getErrorMessage } from 'components/Message';

export default async function createOrg(orgName, access_token, user_metadata, respOrgID) {
  const response = {
    status: '200',
    message: 'OK',
  };
  try {
    const params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/create_org`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        name: orgName,
        user_metadata: user_metadata,
        user_id: respOrgID,
      },
    };
    const response = await axios(params);
    console.log(response);
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
  return response;
}
