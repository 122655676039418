import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { AiOutlineFileText } from 'react-icons/ai';
import { HiOutlineChevronRight, HiOutlineChevronDown } from 'react-icons/hi';
import DataTable from 'components/DataTable';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormContainer from 'components/Form/FormContainer';
import FormRow from './FormRow';

const ReportCommentsValidation = Yup.object().shape({
  resporg_action: Yup.string().max(256, 'Too Long!').required('Investigation Outcome is required'),
  comments_RespOrg: Yup.string().max(256, 'Too Long!'),
  retail_CustName: Yup.string().max(256, 'Too Long!'),
});

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #b8a1e2;
  cursor: pointer;
  &:hover {
    background-color: #3e4c78;
  }

  &:disabled {
    background: #dddddd;
    cursor: inherit;
  }
`;

const RespOrgActionOptions = [
  { value: '', label: '' },
  { value: 'TFN Disabled', label: 'TFN Disabled' },
  {
    value: 'No Fraudulent Activity',
    label: 'No Fraudulent Activity',
  },
  { value: 'Other', label: 'Other' },
];
// const SelectableCell = ({ getValue, row: { index }, column: { id }, table }) => {
//   const initialValue = getValue();
//   // We need to keep and update the state of the cell normally
//   const [value, setValue] = useState(initialValue);

//   // When the input is blurred, we'll call our table meta's updateData function
//   const onBlur = () => {
//     table.options.meta?.updateData(index, id, value);
//   };
//   // If the initialValue is changed external, sync it up with our state
//   useEffect(() => {
//     setValue(initialValue);
//   }, [initialValue]);

//   return (
//     <Select
//       size="md"
//       className="w-40 p-0"
//       isSearchable={false}
//       options={RespOrgActionOptions}
//       value={RespOrgActionOptions.filter((option) => option.value === value)}
//       onChange={(option) => setValue(option.value)}
//       onBlur={onBlur}
//     />
//   );
// };

const OpenReportTable = ({ data, loading, handleUpdate }) => {
  const SubComponent = useCallback(({ row, handleUpdate }) => {
    if (row?.original) {
      return (
        <Formik
          initialValues={{
            ...row.original,
          }}
          onSubmit={handleUpdate}
          validationSchema={ReportCommentsValidation}
        >
          {({ values, touched, errors, isSubmitting, resetForm, dirty, isValid }) => {
            const validatorProps = { touched, errors };
            return (
              <div className="flex flex-col justify-center w-9/12 mx-16">
                <Form>
                  <FormContainer>
                    <FormRow label="Investigation Outcome" name="resporg_action" required {...validatorProps}>
                      <Field name="resporg_action">
                        {({ field, form }) => (
                          <Select
                            field={field}
                            form={form}
                            options={RespOrgActionOptions}
                            value={RespOrgActionOptions.filter((option) => option.value === values?.resporg_action)}
                            onChange={(option) => form.setFieldValue(field.name, option.value)}
                          />
                        )}
                      </Field>
                    </FormRow>
                    <FormRow label="Resp Org Comments" name="comments_RespOrg" {...validatorProps}>
                      <Field type="text" autoComplete="off" name="comments_RespOrg" placeholder="" component={Input} />
                    </FormRow>
                    <FormRow
                      label="Retail Customer Name (Optional to provide)"
                      name="retail_CustName"
                      {...validatorProps}
                    >
                      <Field type="text" autoComplete="off" name="retail_CustName" placeholder="" component={Input} />
                    </FormRow>
                    <div className="flex justify-end gap-2">
                      <Button type="submit" disabled={!(dirty && isValid)}>
                        Submit
                      </Button>
                    </div>
                  </FormContainer>
                </Form>
              </div>
            );
          }}
        </Formik>
      );
    }
    return <></>;
  }, []);

  const renderSubComponent = ({ row }) => {
    return <SubComponent row={row} handleUpdate={handleUpdate} />;
  };
  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        header: () => null, // No header
        id: 'expander', // It needs an ID
        cell: ({ row }) => (
          <>
            {row.getCanExpand() ? (
              <button className="text-lg" {...{ onClick: row.getToggleExpandedHandler() }}>
                {row.getIsExpanded() ? <HiOutlineChevronDown /> : <HiOutlineChevronRight />}
              </button>
            ) : null}
          </>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        subCell: () => null, // No expander on an expanded row
      },
      { header: 'Callback Toll Free Number', accessorKey: 'call_number', footer: (props) => props.column.id },
      {
        header: 'Date of Last Attempted Fraud',
        accessorKey: 'fraud_date',
        size: 300,
        type: 'date',
        footer: (props) => props.column.id,
      },
      {
        header: 'State Where Fraud Occurred',
        accessorKey: 'occurrence_state',
        footer: (props) => props.column.id,
      },
      {
        header: 'Amount Asked For or Paid by the Victim',
        accessorKey: 'dollarAsked',
        footer: (props) => props.column.id,
      },
      {
        header: 'Additional Information',
        accessorKey: 'comments_RepCo',
        footer: (props) => props.column.id,
      },
      {
        header: 'Supporting Files',
        accessorFn: (row) => row.attachmentsDetails?.length || 0,
        footer: (props) => props.column.id,
        cell: ({ row: { original } }) => {
          const { attachmentsDetails } = original;
          if (attachmentsDetails && attachmentsDetails.length > 0) {
            // console.log(attachmentsDetails);
            return (
              <div className="flex flex-row justify-between">
                {attachmentsDetails.map((item) => {
                  return (
                    <a
                      key={item.name}
                      title={item.name}
                      href={item.url}
                      download={item.name}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <AiOutlineFileText />
                    </a>
                  );
                })}
              </div>
            );
          }
        },
      },
      {
        header: 'Date Created',
        accessorKey: 'create_date',
        type: 'date',
        footer: (props) => props.column.id,
        cell: ({
          row: {
            original: { create_date },
          },
        }) => {
          const d = new Date(create_date);
          return <span>{d.toISOString().split('T')[0]}</span>;
        },
      },

      // {
      //   header: 'Resp Org Comments',
      //   accessorKey: 'comments_RespOrg',
      //   cell: EditableCell,
      //   footer: (props) => props.column.id,
      // },
      // {
      //   header: 'Retail Customer Name',
      //   accessorKey: 'retail_CustName',
      //   cell: EditableCell,
      //   footer: (props) => props.column.id,
      // },
      // {
      //   header: 'Action By RespOrg',
      //   accessorKey: 'resporg_action',
      //   size: 250,
      //   cell: SelectableCell,
      //   footer: (props) => props.column.id,
      // },
      // {
      //   header: '',
      //   id: 'action',
      //   disableSortBy: true,
      //   footer: (props) => props.column.id,
      //   cell: ({ row: { original } }) => {
      //     return <Button onClick={() => handleUpdate(original)}>Update</Button>;
      //   },
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns}
      renderRowSubComponent={renderSubComponent}
      getRowCanExpand={() => true}
      toCSV={{ display: true, name: 'open_reports.csv' }}
    />
  );
};

export default OpenReportTable;
