import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const AssignedReportsPieChart = ({ data, precents }) => {
  const chartData = data.map((item) => {
    if (item.entity_ID === 'unknown') {
      return {
        name: 'Unknown',
        value: item.openReports,
        color: item.color,
      };
    }
    if (item.org_name === 'NOT FOUND') {
      return {
        name: `Unnamed (${item.entity_ID.slice(6, 8)})`,
        value: item.openReports,
        color: item.color,
      };
    }

    return {
      name: item.org_name,
      value: item.openReports,
      color: item.color,
    };
  });

  if (!precents) {
    return (
      <div>
        <PieChart width={360} height={360}>
          <Pie data={chartData} cx={180} cy={150} outerRadius={125} fill="#8884d8" dataKey="value">
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
    );
  }

  return (
    <div>
      <PieChart width={360} height={360}>
        <Pie
          data={chartData}
          cx={180}
          cy={150}
          outerRadius={125}
          fill="#8884d8"
          dataKey="value"
          label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default AssignedReportsPieChart;
