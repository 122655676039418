import React, { useState, useEffect, useRef, Fragment } from 'react';
import Table from './Table';
import Pagination from './Pagination';
import Select from './Select/Select';
import Input from './Input/Input';

import TableRowSkeleton from './loaders/TableRowSkeleton';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

export const EditableCell = (props) => {
  const {
    getValue,
    row: { index },
    column: { id },
    table,
  } = props;
  const initialValue = getValue();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    table.options.meta?.updateData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Input
      className="border-transparent bg-transparent hover:border-gray-300 focus:bg-white"
      size="sm"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

const pageSizeOption = [
  { value: 10, label: '10 / page' },
  { value: 20, label: '20 / page' },
  { value: 30, label: '30 / page' },
  { value: 40, label: '40 / page' },
  { value: 50, label: '50 / page' },
];
function Filter({ column, table }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const isDate = column.columnDef.type === 'date';
  const sortedUniqueValues = React.useMemo(
    () => (typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column.getFacetedUniqueValues()]
  );

  let minDate, maxDate;
  if (isDate) {
    minDate = Date.parse(column.getFacetedMinMaxValues()?.[0]);
    maxDate = Date.parse(column.getFacetedMinMaxValues()?.[1]);
    if (isNaN(minDate)) minDate = '';
    if (isNaN(maxDate)) maxDate = '';
  }
  return isDate ? (
    <>
      <DebouncedInput
        type="date"
        value={columnFilterValue ?? ''}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  ) : typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old) => [value, old?.[1]])}
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old) => [old?.[0], value])}
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ''}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
}

function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="flex justify-end">
      <div className="flex items-center mb-4">
        <span className="mr-2">Search:</span>
        <Input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
      </div>
    </div>
  );
}

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip];
}

const DataTable = (props) => {
  const {
    data,
    columns,
    loading,
    skeletonAvatarColumns,
    skeletonAvatarProps,
    renderRowSubComponent,
    getRowCanExpand,
    toCSV,
  } = props;

  const [tableData, setData] = useState(data);
  const [isLoading, setLoading] = useState(loading);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  useEffect(() => {
    setData(data);
    setLoading(loading);
  }, [data, loading]);
  const table = useReactTable({
    data: tableData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    debugHeaders: true,
    debugColumns: false,
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip age index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });

  // Returns visible rows w/ filters applied
  const getFilteredRows = () => {
    const filteredRows = table.getFilteredRowModel().rows.map((row) => row.original);

    // Get the accessorKey from each visible column
    const visibleColumns = columns.filter((column) => column.accessorKey).map((column) => column.accessorKey);

    // Remove any columns that are not visible
    filteredRows.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (!visibleColumns.includes(key)) {
          delete row[key];
        }
      });
    });

    return filteredRows;
  };

  // Pushes table as a CSV file to the user
  function buildCSV() {
    // Get the filtered rows
    const rows = getFilteredRows();
    // filename is the name of the CSV file + current date
    const filename = toCSV.name.split('.')[0] + '_' + new Date().toISOString() + '.csv';

    const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(rowsToCSV(rows));

    const link = document.createElement('a');
    link.href = csvContent;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up

    // Helper function to convert rows to CSV format
    function rowsToCSV(data) {
      const header = Object.keys(data[0]).join(',');
      const rowsContent = data.map((row) => Object.values(row).join(',')).join('\n');
      return `${header}\n${rowsContent}`;
    }
  }

  const onPaginationChange = (page) => {
    table.setPageIndex(page - 1);
  };

  const onSelectChange = (value) => {
    table.setPageSize(Number(value));
  };

  const downloadButtonStyles = {
    cursor: 'pointer',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.30)',
    borderRadius: '5px',
    height: '40px',
    width: '150px',
    backgroundColor: 'white',
    marginRight: '10px',
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {toCSV?.display && (
          <button style={downloadButtonStyles} onClick={buildCSV}>
            Download Table
          </button>
        )}
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search all columns..."
        />
      </div>
      <Table>
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {<Sorter sort={header.column.getIsSorted()} />}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </THead>
        {isLoading ? (
          <TableRowSkeleton
            columns={columns.length}
            rows={table.getState().pagination.pageSize}
            avatarInColumns={skeletonAvatarColumns}
            avatarProps={skeletonAvatarProps}
          />
        ) : (
          <TBody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
                    })}
                  </Tr>
                  {row.getIsExpanded() && (
                    <Tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <Td colSpan={row.getVisibleCells().length}>{renderRowSubComponent({ row })}</Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })}
          </TBody>
        )}
      </Table>
      <div className="flex items-center justify-between mt-4">
        <Pagination
          pageSize={table.getState().pagination.pageSize}
          currentPage={table.getState().pagination.pageIndex + 1}
          total={tableData?.length}
          onChange={onPaginationChange}
        />
        <div style={{ minWidth: 130 }}>
          <Select
            size="sm"
            isSearchable={false}
            value={pageSizeOption.filter((option) => option.value === table.getState().pagination.pageSize)}
            options={pageSizeOption}
            onChange={(option) => onSelectChange(option.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
