import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading';

const PageBody = () => {
  const { isLoading } = useAuth0();

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="page-body">
          <div className="newBody">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default PageBody;
