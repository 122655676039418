import React, { useEffect, useState, useContext } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';

import fetchProfile from 'api/fetchProfile';
import updateProfile from 'api/updateProfile';

import { UserContext } from 'auth/UserContext';
import Loading from 'components/Loading';
import Input from 'components/Input';
import { SubmitButton, CancelButton } from 'components/Button';
import EditOrgValidationSchema from 'validation/EditOrgValidationSchema';
import { getErrorMessage, MessageBar } from 'components/Message';

const Container = styled.div`
  width: 80%;
  margin: auto;
  background-color: white;
  border-radius: 5px;
`;

const ContainerForm = styled.div`
  color: #373d3f;
  padding: 2rem 5rem 5rem 5rem;
  border: 1px solid rgb(229, 229, 229);
  h1 {
    font-size: 1.5rem;
    margin-left: 3rem;
  }
`;

const EditOrg = () => {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState({});
  const { logout } = useAuth0();
  const {
    state: { id },
  } = useLocation();

  if (!userInfo) {
    logout({ returnTo: window.location.origin });
  }

  const [isLoading, setLoading] = useState(true);
  const [formInitial, setFormInitial] = useState({});

  const getProfile = async () => {
    try {
      setLoading(true);
      let response = await fetchProfile(id, userInfo.accessToken);
      console.log(response);
      setFormInitial({
        orgName: response.name,
        orgDomain: response.user_metadata.domain,
        orgNote: response.user_metadata.note,
        orgType: response.user_metadata.type,
        orgRole: response.user_metadata.role,
      });
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, id]);

  const handleSubmit = async (values) => {
    setLoading(true);
    setMessage({});

    let user_metadata = {
      domain: values.orgDomain,
      note: values.orgNote,
      role: values.orgRole,
      type: values.orgType,
    };
    try {
      await updateProfile(id, userInfo.accessToken, user_metadata);
      navigate(`/organizations/singleOrg`, { state: { id } });
    } catch (e) {
      const msg = getErrorMessage(e);
      setMessage({ type: 'error', msg });
    } finally {
      setLoading(false);
    }
  };

  if (!formInitial) {
    return <Loading />;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Container>
            <MessageBar type={message.type}>{message.msg}</MessageBar>
            <ContainerForm>
              <Formik initialValues={formInitial} validationSchema={EditOrgValidationSchema} onSubmit={handleSubmit}>
                {(props) => (
                  <Form>
                    <h1>Edit {formInitial.orgName}</h1>
                    <Input label="Organization Domain" required name="orgDomain" />
                    <Input label="Organization Note" name="orgNote" />
                    <Input label="Organization Type" required name="orgType" />
                    <div style={{ float: 'right' }}>
                      <Link to={'/organizations/singleOrg'} state={{ id }}>
                        <CancelButton>Back</CancelButton>
                      </Link>
                      <SubmitButton type="submit" disabled={!(props.dirty && props.isValid)}>
                        Create
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </ContainerForm>
          </Container>
        </>
      )}
    </>
  );
};

export default withAuthenticationRequired(EditOrg, {
  onRedirecting: () => <Loading />,
});
