import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Input from 'components/Input';
import { Button } from 'components/Button';
import { NewReportValidationSchema } from 'validation/EditProfileValidationSchema';
import CheckBox from 'components/CheckBox';
import FilesUpload from './FilesUpload';
import createReport from 'api/createReport';
import Loading from 'components/Loading';
import { getErrorMessage, MessageBar } from 'components/Message';
import getPresignedUrl from 'api/getPresignedUrl';

// const ReportEntityOptions = [
//   { key: 'Yes', value: 'true' },
//   { key: 'No', value: 'false' },
// ];

// const DnoOptions = [
//   { key: 'Yes', value: 'true' },
//   { key: 'No', value: 'false' },
//   { key: 'Not Sure', value: 'notSure' },
// ];
const ReportNew = ({ userInfo }) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const navigate = useNavigate();
  const submitHandler = async (value) => {
    const data = {
      RepCo_ID: userInfo.user_metadata.OrgID,
      call_number: value.number,
      fraudDate: value.date,
      caller_id: value.callerID,
      isCallerid_RepCo: value.isCalleridRepCo,
      isCallerid_oc: value.dno,
      occurrence_state: value.state,
      impactOrg: value.impactedOrg,
      dollarAsked: value.dollarAsked,
      comments_RepCo: value.comments,
      category: userInfo.user_metadata.Category,
      image: '',
      // image: value.fileList,  //add image list later
    };
    setLoading(true);
    setMessage({});
    try {
      let response = await createReport(data, userInfo.accessToken);
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }

      const reportId = response.data.report_id;
      if (value.fileList.length === 0) {
        setMessage({ type: 'success', msg: 'New report successfully created' });
        return;
      }

      const fileData = value.fileList.map((item) => {
        return {
          reportId,
          name: item.name,
        };
      });
      response = await getPresignedUrl({ files: fileData }, userInfo.accessToken);

      const uploadAllFiles = response.presignedUrls.map((item) => {
        const fileInfo = value.fileList.filter((file) => file.name === item.key);

        return fetch(item.url, {
          method: 'PUT',
          headers: { 'Content-type': fileInfo[0].type },
          body: fileInfo[0].file,
        });
      });

      const uploadFileToUrl = Promise.all(uploadAllFiles);
      response = await uploadFileToUrl;
      const uploadSuccess = response.every((item) => {
        return item.status === 200;
      });

      if (uploadSuccess) {
        const msg =
          'New report successfully created. You can create another report below or click on the Back button to return to Reports page.';
        setMessage({ type: 'success', msg });
      }
    } catch (e) {
      const msg = getErrorMessage(e);
      setMessage({ type: 'error', msg });
    } finally {
      setLoading(false);
    }
  };
  const onBack = () => {
    navigate('/reports');
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{
              number: '',
              date: '',
              callerID: '',
              isCalleridRepCo: 'false',
              dno: 'false',
              state: '',
              impactedOrg: '',
              dollarsAsked: '',
              comments: '',
              fileList: [],
              check1: false,
              check2: false,
              check3: false,
            }}
            validationSchema={NewReportValidationSchema}
            onSubmit={submitHandler}
          >
            {({ values, touched, errors, isValid, dirty }) => (
              <Form noValidate autoComplete="off">
                <div>
                  <div className="mx-12">
                    <h5 className="text-xl font-semibold">Fraud Reporting Form</h5>
                    <p className="mb-6">Create a new fraud reporting form and upload supporting documents</p>
                  </div>
                  <div className="border-2 border-gray-200 rounded-md bg-white">
                    <MessageBar type={message.type}>{message.msg}</MessageBar>
                    <div className="grid grid-cols-3 gap-4 py-8 divide-x divide-slate-200">
                      <div className="col-span-2">
                        <Input label="Callback Toll Free Number " name="number" required />
                        <Input label="Date of Last Attempted Fraud" name="date" type="date" required />
                        <Input label="What Number Appeared on the Caller ID?" name="callerID" required />
                        <Input label="State Where Fraud Occurred" name="state" required />
                        <Input label="Impacted Organization (if not your own)" name="impactedOrg" />
                        <Input label="Amount Asked For or Paid by the Victim" name="dollarsAsked" type="number" />
                        <Input label="Additional Information" name="comments" />
                      </div>
                      <FilesUpload values={values} errors={errors} touched={touched} />
                    </div>
                  </div>
                  <div className="mx-12 my-4 py-4">
                    <CheckBox
                      label="We, the Reporting Entity or Responsible Organization, verify that the Callback Toll-Free Number(s) reported is not being used by us or any third party on our behalf."
                      name="check1"
                    />
                    <CheckBox
                      label="We verify that the Callback Toll-Free Number(s) being reported is being used in an impersonation scam against our organization, or an organization we are contracted to report on behalf of."
                      name="check2"
                    />
                    <CheckBox
                      label="We verify that our use of FRServices, and this Reporting Form, are governed by the FRServices Terms and Conditions."
                      name="check3"
                    />
                  </div>
                  <div className="flex justify-end gap-2">
                    <Button type="button" onClick={onBack}>
                      Back
                    </Button>
                    <Button
                      type="submit"
                      disabled={!(dirty && isValid && values.check1 && values.check2 && values.check3)}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default withAuthenticationRequired(ReportNew, {
  onRedirecting: () => <Loading />,
});
