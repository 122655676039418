import axios from 'axios';

export default async function createReport(data, access_token) {
  let value = null;
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/createReport`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: data,
    };
    await axios(params).then((response) => {
      console.log(response);
      value = response;
    });
  } catch (error) {
    value = error.response;
  }
  return value;
}
