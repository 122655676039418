import axios from 'axios';

export default async function fetchAllOrgs(userID, access_token, pageIndex = undefined, pageSize = undefined) {
  let orgs = null;
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/getall_org`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        user_id: userID,
        page_index: pageIndex,
        page_size: pageSize,
      },
    };
    await axios(params).then((response) => {
      console.log(response);
      orgs = response.data;
    });
  } catch (error) {
    console.log(error.message);
  }
  return orgs;
}
