import { useEffect, useState, useContext } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import fetchProfile from 'api/fetchProfile';
import createUser from 'api/createUser';
import { UserContext } from 'auth/UserContext';
import Input from 'components/Input';
import Select from 'components/Select';
import Loading from 'components/Loading';
import { SubmitButton, CancelButton } from 'components/Button';
import UserValidationSchema from 'validation/UserValidationSchema';
import { getErrorMessage, MessageBar } from 'components/Message';

const Container = styled.div`
  width: 80%;
  margin: auto;
  background-color: white;
  border-radius: 5px;
`;

const ContainerForm = styled.div`
  color: #373d3f;
  padding: 2rem 5rem 5rem 5rem;
  border: 1px solid rgb(229, 229, 229);
  h1 {
    font-size: 1.5rem;
    margin-left: 3rem;
  }
`;

const AddUser = () => {
  const { userInfo } = useContext(UserContext);
  const {
    state: { id },
  } = useLocation();
  // const [message, setMessage] = useState({ type: 'error', msg: 'error' });
  const [message, setMessage] = useState({});
  const navigate = useNavigate();
  const [org, setOrg] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const RespOrgRoleOptions = [
    {
      key: 'Select Role',
      value: null,
    },
    {
      key: 'Resp Org Admin',
      value: '2',
    },
    {
      key: 'Resp Org User',
      value: '3',
    },
  ];

  const UserRoleOptions = [
    {
      key: 'Select Role',
      value: null,
    },
    {
      key: 'Reporting Entity Admin',
      value: '4',
    },
    {
      key: 'Reporting Entity user',
      value: '5',
    },
  ];

  useEffect(() => {
    const getProfile = async () => {
      try {
        setLoading(true);
        let response = await fetchProfile(id, userInfo.accessToken);
        setOrg(response);
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };
    getProfile();
  }, [userInfo, id]);

  const createNewUser = async (values) => {
    let password = '';
    let user_metadata = {
      MobilePhone: values.mobilePhone,
      TimeZone: values.timeZone,
      WorkPhone: values.workPhone,
      first_name: values.firstName,
      last_name: values.lastName,
      role: values.userRole,
      title: values.title,
      OrgName: org.name,
      OrgID: org.user_id,
      Category: org.user_metadata.type,
    };

    console.log(user_metadata);

    try {
      setLoading(true);
      await createUser(values.email, password, userInfo.accessToken, user_metadata);
      navigate(`/organizations/singleOrg`, { state: { id } });
    } catch (e) {
      const msg = getErrorMessage(e);
      setMessage({ type: 'error', msg });
    } finally {
      setLoading(false);
    }
  };

  if (!org) {
    return null;
  }

  return (
    <>
      {!isLoading ? (
        <>
          <Container>
            <MessageBar type={message.type}>{message.msg}</MessageBar>
            <ContainerForm>
              <h1>{org.name ? `Adding user to: ${org.name}` : 'Loading'}</h1>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  title: '',
                  workPhone: '',
                  mobilePhone: '',
                  timeZone: '',
                  email: '',
                  userRole: '',
                  orgRole: org.user_metadata.role,
                }}
                onSubmit={createNewUser}
                validationSchema={UserValidationSchema}
              >
                {(props) => (
                  <Form>
                    <Input label="First Name" name="firstName" required />
                    <Input label="Last Name" name="lastName" required />
                    <Input label="Title" name="title" required />
                    <Input label="Work Phone" name="workPhone" required />
                    <Input label="Mobile Phone" name="mobilePhone" required />
                    <Input label="Time Zone" name="timeZone" />
                    <Input label="Email" name="email" required />
                    {props.values.orgRole === '6' ? (
                      <Select label="Organization Role" name="userRole" required options={RespOrgRoleOptions} />
                    ) : (
                      <Select label="Organization Role" name="userRole" required options={UserRoleOptions} />
                    )}
                    <div style={{ float: 'right' }}>
                      <Link to={'/organizations/singleOrg'} state={{ id }}>
                        <CancelButton>Back</CancelButton>
                      </Link>
                      <SubmitButton type="submit" disabled={!(props.dirty && props.isValid)}>
                        Create
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </ContainerForm>
          </Container>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default withAuthenticationRequired(AddUser, {
  onRedirecting: () => <Loading />,
});
