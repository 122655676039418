import { useState } from 'react';
import styles from './styles/Analytics.module.css';
import RespOrgAnalytics from './RespOrgAnalytics';
import RepCoAnalytics from './RepCoAnalytics';

const Analytics = () => {
  const [tab, setTab] = useState('resporg');

  return (
    <>
      <div className={styles.tabDiv}>
        <button
          className={
            tab === 'resporg' ? `${styles.tabButtonActive} ${styles.tabLeft}` : `${styles.tabButton} ${styles.tabLeft}`
          }
          onClick={() => {
            setTab('resporg');
          }}
        >
          Resp Orgs
        </button>
        <button
          className={
            tab === 'repco' ? `${styles.tabButtonActive} ${styles.tabRight}` : `${styles.tabButton} ${styles.tabRight}`
          }
          onClick={() => {
            setTab('repco');
          }}
        >
          Reporting Companies
        </button>
      </div>
      {tab === 'resporg' && <RespOrgAnalytics />}
      {tab === 'repco' && <RepCoAnalytics />}
    </>
  );
};

export default Analytics;
