import stylesProfile from '../styles/Profile.module.css';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import signUp from 'api/signUp';
import Loading from 'components/Loading';
import Input from 'components/Input';
import { SubmitButton, CancelButton } from 'components/Button';
import SignUpValidationSchema from 'validation/SignUpValidationSchema';

const Container = styled.div`
  width: 60%;
  margin: auto;
  background-color: white;

  border-radius: 5px;
`;

const ContainerForm = styled.div`
  color: #373d3f;
  padding: 2rem 5rem 5rem 5rem;
  border: 1px solid rgb(229, 229, 229);
`;

const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const { orgName, contactEmail, contactName, contactPhone } = values;
      await signUp(orgName, contactName, contactEmail, contactPhone);
      navigate('/');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Container>
          <ContainerForm>
            <h1 className={stylesProfile.profileupdatetitle}>Sign Up</h1>
            <Formik
              initialValues={{
                orgName: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={SignUpValidationSchema}
            >
              {(props) => (
                <Form>
                  <Input label="Organization Name" name="orgName" required />
                  <Input label="Contact Name" name="contactName" required />
                  <Input label="Contact Email" name="contactEmail" required />
                  <Input label="Contact Phone" name="contactPhone" required />
                  <div style={{ float: 'right' }}>
                    <Link to="/">
                      <CancelButton>Back</CancelButton>
                    </Link>
                    <SubmitButton type="submit" disabled={!(props.dirty && props.isValid)}>
                      Sign Up
                    </SubmitButton>
                  </div>
                </Form>
              )}
            </Formik>
          </ContainerForm>
        </Container>
      )}
    </>
  );
};

export default SignUp;
