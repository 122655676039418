import axios from 'axios';

export default async function getReportsByCategory(access_token, category) {
  console.log(`Getting reports by category: ${category}`);
  let numReports = 0;
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/getReportsByCategory`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`,
      },
      data: {
        category: category,
      },
    };
    await axios(params).then((response) => {
      console.log(response);
      numReports = response.data;
    });
  } catch (error) {
    console.log(error.message);
  }
  return numReports;
}
