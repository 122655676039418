import React from 'react';
import styles from '../styles/ChangePassword.module.css';
import { SubmitButton } from 'components/Button';

export function ChangePassword({ changePwd }) {

    const handleChangePwd = () => {
        changePwd();
    };

    return (
        <div>
            <h1 className={styles.title}>Change Password</h1>
            <p className={styles.updatemessage}>
                If you wish to change your FRServices user account password, please click
                the button below.
            </p>
            <div style={{ float: 'right' }}>
                <SubmitButton
                    type="button"
                    name={'Profile_Cng_Pwd_Btn'}
                    onClick={() => handleChangePwd()}
                >
                    Change Password
                </SubmitButton>
            </div>
        </div>
    );
}
