import React from 'react';
import ReactApexChart from 'react-apexcharts';

function PieChart(props) {
  let state = {
    series: [props.open, props.closed],
    options: {
      chart: {
        type: 'pie',
      },
      labels: ['Open Reports', 'Closed Reports'],
      legend: {
        show: false,
      },
    },
  };

  return <ReactApexChart options={state.options} series={state.series} type="pie" width="200"/>;
}

export default PieChart;
