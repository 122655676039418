import axios from 'axios';

export default async function fetchProfile(userID, access_token) {
  let profile = null;
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/get_user_profile`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        user_id: userID,
      },
    };
    await axios(params).then((response) => {
      console.log(response);
      profile = response.data;
    });
  } catch (error) {
    console.log(error.message);
  }
  return profile;
}
