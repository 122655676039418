import axios from 'axios';
import { getErrorMessage } from 'components/Message';

export default async function deleteUser(userID, access_token) {
  try {
    const params = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/delete_user/${userID}`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        user_id: userID,
      },
    };
    const resp = await axios(params);
    console.log(resp);
  } catch (error) {
    throw new Error(getErrorMessage);
  }
}
