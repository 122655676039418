import React, { useState } from 'react';
import TableContext from './TableContext';
const TableProvider = ({ children }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const tableInfo = {
    pageIndex,
    pageSize,
  };

  const setTableInfo = ({ pageIndex, pageSize }) => {
    if (pageIndex !== undefined) {
      setPageIndex(pageIndex);
    }
    if (pageSize !== undefined) {
      setPageSize(pageSize);
    }
  };
  return <TableContext.Provider value={{ tableInfo, setTableInfo }}>{children}</TableContext.Provider>;
};

export default TableProvider;
