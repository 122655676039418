import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import styles from '../styles/Organization.module.css';
import createOrg from 'api/createOrg';
import { useContext } from 'react';
import { UserContext } from 'auth/UserContext';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Select from 'components/Select';
import { SubmitButton, CancelButton } from 'components/Button';
import OrganizationValidationSchema from 'validation/OrganizationValidationSchema';
import { useState } from 'react';
import { getErrorMessage, MessageBar } from 'components/Message';

const Container = styled.div`
  width: 80%;
  margin: auto;
  background-color: white;

  border-radius: 5px;
`;

const ContainerForm = styled.div`
  color: #373d3f;
  padding: 2rem 5rem 5rem 5rem;
  border: 1px solid rgb(229, 229, 229);
`;

const AddOrg = () => {
  const { userInfo } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  // const [message, setMessage] = useState({ type: 'error', msg: 'error' });
  const [message, setMessage] = useState({});
  console.log(userInfo);
  const navigate = useNavigate();

  const roleDropDownOptions = [
    { key: 'Resp Org', value: '6' },
    { key: 'Reporting Entity', value: '7' },
  ];

  const typeDropDownOptions = [
    { key: 'Select', value: '' },
    { key: 'Entertainment', value: 'Entertainment' },
    { key: 'Online Retailer', value: 'Online Retailer' },
    { key: 'Tech Retailer', value: 'Tech Retailer' },
    { key: 'Utility', value: 'Utility' },
    { key: 'Enforcement', value: 'Enforcement' },
    { key: 'Other', value: 'Other' },
  ];

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    if (values.orgRole === '6') {
      actions.setFieldValue('respOrgID', '');
    }

    if (values.orgRole === '7') {
      actions.setFieldValue('orgType', '');
    }

    const user_metadata = {
      domain: values.orgDomain,
      note: values.orgNote,
      role: values.orgRole,
      type: values.orgType,
    };
    try {
      await createOrg(values.orgName, userInfo.accessToken, user_metadata, values.respOrgID);
      navigate('/organizations');
    } catch (e) {
      const msg = getErrorMessage(e);
      setMessage({ type: 'error', msg });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.orgListControls}>
            <h1 className="pageTitle">Add Organization</h1>
          </div>
          <Container>
            <MessageBar type={message.type}>{message.msg}</MessageBar>
            <ContainerForm>
              <Formik
                initialValues={{
                  orgName: '',
                  orgDomain: '',
                  orgNote: '',
                  orgType: '',
                  orgRole: '6',
                  respOrgID: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={OrganizationValidationSchema}
              >
                {(props) => (
                  <Form>
                    <Input label="Organization Name" name="orgName" required />
                    <Input label="Organization Domain" name="orgDomain" required />
                    <Input label="Organization Note" name="orgNote" />
                    <Select label="Organization Role" name="orgRole" required options={roleDropDownOptions} />
                    {props.values.orgRole === '6' ? (
                      <Input label="Entity Number" name="respOrgID" required />
                    ) : (
                      <Select label="Organization Type" name="orgType" options={typeDropDownOptions} required />
                    )}
                    <div style={{ float: 'right' }}>
                      <Link to="/organizations">
                        <CancelButton>Back</CancelButton>
                      </Link>
                      <SubmitButton type="submit" disabled={!(props.dirty && props.isValid)}>
                        Create
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </ContainerForm>
          </Container>
        </>
      )}
    </>
  );
};

export default withAuthenticationRequired(AddOrg, {
  onRedirecting: () => <Loading />,
});
