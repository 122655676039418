import React from 'react';
import {
  HiCheckCircle,
  HiOutlineInformationCircle,
  HiOutlineExclamation,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';
import Dialog from './Dialog';
import { Button, CancelButton } from './Button';
import PropTypes from 'prop-types';

const StatusIcon = ({ status }) => {
  switch (status) {
    case 'info':
      return (
        <span className="text-2xl">
          <HiOutlineInformationCircle />
        </span>
      );
    case 'success':
      return (
        <span className="text-2xl">
          <HiCheckCircle />
        </span>
      );
    case 'warning':
      return (
        <span className="text-2xl">
          <HiOutlineExclamationCircle />
        </span>
      );
    case 'danger':
      return (
        <span className="text-2xl">
          <HiOutlineExclamation color="red" />
        </span>
      );

    default:
      return null;
  }
};

const ConfirmDialog = (props) => {
  const { type, title, children, onCancel, onConfirm, cancelText, confirmText, confirmButtonColor, ...rest } = props;

  const handleCancel = () => {
    onCancel?.();
  };

  const handleConfirm = () => {
    onConfirm?.();
  };

  return (
    <Dialog
      style={{
        marginTop: 250,
      }}
      contentClassName="pb-0 px-0"
      {...rest}
    >
      <div className="px-6 pb-6 pt-2 flex">
        <div>
          <StatusIcon status={type} />
        </div>
        <div className="ml-4">
          <h5 className="mb-2 text-xl">{title}</h5>
          {children}
        </div>
      </div>
      <div className="text-right px-6 py-3 bg-gray-100 dark:bg-gray-700 rounded-bl-lg rounded-br-lg">
        <CancelButton size="sm" className="mr-2 " onClick={handleCancel}>
          {cancelText}
        </CancelButton>
        <Button size="sm" className="my-0" variant="solid" onClick={handleConfirm} color={confirmButtonColor}>
          {confirmText}
        </Button>
      </div>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
};

ConfirmDialog.defaultProps = {
  type: 'info',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};

export default ConfirmDialog;
