import React from 'react';
import styles from '../styles/Analytics.module.css';

const TableRow = ({ item, uniqueYears }) => {
  let respOrgTitle;

  respOrgTitle = item.org_name;
  if (item.org_name === 'NOT FOUND') {
    // add org name to respOrgTitle
    respOrgTitle = 'Unnamed';
  }
  if (item.entity_ID === 'unknown') {
    respOrgTitle = 'All Unknown Orgs:';
  }

  return (
    <tr key={item.entity_ID} style={{ color: item.color }} className={styles.yearsTableRows}>
      <td className={styles.tableRowsTD}>{respOrgTitle}</td>
      {uniqueYears.map((year) => (
        <td style={{ textAlign: 'center' }} key={year}>
          {item.reportsByYear.find((data) => data.year === year)?.count || 0}
        </td>
      ))}
    </tr>
  );
};

const ReportsByYearTableRepCo = ({ data }) => {
  let localData = [...data];
  // Sort the localdata alphabetically by org_name
  // Keep all orgs where org_name === 'NOT FOUND' at the bottom
  // Sort all orgs where org_name === 'NOT FOUND' by entity_ID
  // Ignore all casing

  // The following block was created entirely by copilot based on the above comments
  localData.sort((a, b) => {
    if (a.org_name === 'NOT FOUND' && b.org_name !== 'NOT FOUND') {
      return 1;
    } else if (a.org_name !== 'NOT FOUND' && b.org_name === 'NOT FOUND') {
      return -1;
    } else if (a.org_name === 'NOT FOUND' && b.org_name === 'NOT FOUND') {
      return a.entity_ID.toLowerCase() > b.entity_ID.toLowerCase() ? 1 : -1;
    } else {
      return a.org_name.toLowerCase() > b.org_name.toLowerCase() ? 1 : -1;
    }
  });

  // Move the org where entity_ID === 'unknown' to the top
  const unknownOrgIndex = localData.findIndex((item) => item.entity_ID === 'unknown');
  const unknownOrg = localData.splice(unknownOrgIndex, 1);
  localData = [...unknownOrg, ...localData];

  // Extract the unique years from the data and reverse the array
  const uniqueYears = [
    ...new Set(localData.flatMap((item) => item.reportsByYear.map((yearData) => yearData.year))),
  ].reverse();

  return (
    <>
      <table className={styles.yearsTable}>
        <thead className={styles.yearsTableHead}>
          <tr>
            <th style={{ textAlign: 'left', padding: '5px 10px' }}>Reporting Companies</th>
            {uniqueYears.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {localData.map((item) => (
            <TableRow key={item.entity_ID} item={item} uniqueYears={uniqueYears} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ReportsByYearTableRepCo;
