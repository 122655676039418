import React from 'react';
import ReactApexChart from 'react-apexcharts';

function RadialChart(props) {
  let precent;
  if (props.totalReports === 0) {
    precent = 0;
  } else {
    precent = Math.round((props.orgReports.length / props.totalReports) * 100);
  }

  let state = {
    series: [precent],
    options: {
      chart: {
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
            },
            value: {
              fontSize: '16px',
            },
            total: {
              show: true,
              label: 'Total',
              formatter: function () {
                return `${precent}%`;
              },
            },
          },
        },
      },
      labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
    },
  };

  return <ReactApexChart options={state.options} series={state.series} type="radialBar" width="245" />;
}

export default RadialChart;
