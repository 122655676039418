import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SharedLayout from '../layout/SharedLayout';
import Reports from 'pages/Reports';
import EditProfile from 'pages/EditProfile';
import Organizations from 'pages/Organizations';
import AddOrg from 'pages/AddOrg';
import EditOrg from 'pages/EditOrg';
import Profile from 'pages/Profile';
import SingleOrg from 'pages/SingleOrg';
import AddUser from 'pages/AddUser';
import { useAuth0 } from '@auth0/auth0-react';
import fetchProfile from 'api/fetchProfile';
import SignUp from 'pages/SignUp';
import Loading from 'components/Loading';
import { UserContext } from 'auth/UserContext';
import Login from 'pages/Login';
import ReportNew from 'pages/ReportNew';
import Home from 'pages/Home';
import Analytics from 'pages/Analytics/Analytics';

function App() {
  const { getAccessTokenSilently, user, isLoading, error } = useAuth0();
  const [fetchUser, setFetchUser] = useState(1);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (user) {
      const getProfile = async () => {
        const access_token = await getAccessTokenSilently();
        const response = await fetchProfile(user.sub, access_token);
        setUserInfo({ ...response, accessToken: access_token });
      };
      getProfile();
    }
  }, [getAccessTokenSilently, user]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={{ setUserInfo, userInfo }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<Login />} />
            <Route path="home" element={<Home userInfo={userInfo} />} />
            <Route path="profile" element={<Profile />} />
            <Route path="updateProfile" element={<EditProfile fetchUser={fetchUser} setFetchUser={setFetchUser} />} />
            <Route path="organizations">
              <Route index element={<Organizations />} />
              <Route path="add" element={<AddOrg />} />
              <Route path="edits" element={<EditOrg />} />
              <Route path="singleOrg" element={<SingleOrg />} />
              <Route path="addusers" element={<AddUser />} />
            </Route>
            <Route path="reports">
              <Route index element={<Reports userInfo={userInfo} />} />
              <Route path="addReport" element={<ReportNew userInfo={userInfo} />} />
            </Route>
            <Route path="signup" element={<SignUp />} />
            <Route path="Analytics" element={<Analytics />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
