import styled from 'styled-components';

const SubmitButton = styled.button`
  margin: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #b8a1e2;
  cursor: pointer;
  &:hover {
    background-color: #3e4c78;
  }

  &:disabled {
    background: #dddddd;
    cursor: inherit;
  }
`;

const CancelButton = styled.button`
  margin-bottom: 10px;
  margin: 10px;
  padding: 10px 20px;

  border-radius: 5px;
  cursor: pointer;

  background-color: white;
  border: 1px solid #3e4c78;
  color: #3e4c78;

  &:hover {
    background-color: #3e4c78;
    border: 1px solid #3e4c78;
    color: white;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #b8a1e2;
  cursor: pointer;
  &:hover {
    background-color: #3e4c78;
  }

  &:disabled {
    background: #dddddd;
    cursor: inherit;
  }
`;
export { SubmitButton, CancelButton, Button };
