import DataTable from 'components/DataTable';
import React, { useMemo } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';

const ClosedReportTable = ({ data, loading }) => {
  const columns = useMemo(
    () => [
      { header: 'Callback Toll Free Number', accessorKey: 'call_number' },
      {
        header: 'Investigation Outcome',
        accessorKey: 'resporg_action',
        Cell: ({ cell: { value } }) => <span>{value}</span>,
      },
      {
        header: 'Date of Last Attempted Fraud',
        accessorKey: 'fraud_date',
        type: 'date',
      },
      {
        header: 'What Number Appeared on the Caller ID?',
        accessorKey: 'caller_id',
        footer: (props) => props.column.id,
      },
      {
        header: 'State Where Fraud Occurred',
        accessorKey: 'occurrence_state',
      },
      {
        header: 'Impacted Organization (If not your own)',
        accessorKey: 'impactOrg',
        footer: (props) => props.column.id,
      },
      {
        header: 'Amount Asked For or Paid by the Victim',
        accessorKey: 'dollarAsked',
        footer: (props) => props.column.id,
      },
      {
        header: 'Additional Information',
        accessorKey: 'comments_RepCo',
      },
      {
        header: 'Supporting Files',
        accessorFn: (row) => row.attachmentsDetails?.length || 0,
        cell: ({ row: { original } }) => {
          const { attachmentsDetails } = original;
          if (attachmentsDetails && attachmentsDetails.length > 0) {
            // console.log(attachmentsDetails);
            return (
              <div className="flex flex-row justify-between">
                {attachmentsDetails.map((item) => {
                  return (
                    <a
                      key={item.name}
                      title={item.name}
                      href={item.url}
                      download={item.name}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <AiOutlineFileText />
                    </a>
                  );
                })}
              </div>
            );
          }
        },
      },
      {
        header: 'Date Created',
        accessorKey: 'create_date',
        type: 'date',
        footer: (props) => props.column.id,
        cell: ({
          row: {
            original: { create_date },
          },
        }) => {
          const d = new Date(create_date);
          return <span>{d.toISOString().split('T')[0]}</span>;
        },
      },
      {
        header: 'Date Closed',
        accessorKey: 'close_date',
        type: 'date',
        footer: (props) => props.column.id,
        cell: ({
          row: {
            original: { close_date },
          },
        }) => {
          const d = new Date(close_date);
          return <span>{d.toISOString().split('T')[0]}</span>;
        },
      },
    ],
    []
  );

  return <DataTable data={data} loading={loading} columns={columns} toCSV={{ display: true, name: 'closed_reports.csv' }}/>;
};

export default ClosedReportTable;
