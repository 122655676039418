import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts';

const TopFiveTablesRepCo = ({ data, chartType }) => {
  // Copy the data array and remove the unknown entity_ID
  let localData = [...data];
  localData = localData.filter((item) => item.entity_ID !== 'unknown');

  let topFiveTotalReports;
  let topFiveOpenReports;
  let topFiveLongestOpenAvg;

  // Top 5 open reports by entity_ID to topFiveTotalReports
  topFiveTotalReports = localData
    .sort((a, b) => b.totalReports - a.totalReports)
    .slice(0, 5)
    .map((item) => {
      return {
        // if org_name === NOT FOUND, set to 'Unnamed', otherwise slice to 8 chars and add '...' to end
        name: item.org_name === 'NOT FOUND' ? 'Unnamed' : `${item.org_name.slice(0, 8)}...`,
        open: item.openReports,
        closed: item.totalReports - item.openReports,
        color: item.color,
      };
    });

  // Top 5 avg days open by entity_ID to topFiveOpenReports
  topFiveOpenReports = localData
    .sort((a, b) => b.openReports - a.openReports)
    .slice(0, 5)
    .map((item) => {
      return {
        // if org_name === NOT FOUND, set to 'Unnamed', otherwise slice to 8 chars and add '...' to end
        name: item.org_name === 'NOT FOUND' ? 'Unnamed' : `${item.org_name.slice(0, 8)}...`,
        open: item.openReports,
        color: item.color,
      };
    });

  // Top 5 closed reports by entity_ID to topFiveLongestOpenAvg
  topFiveLongestOpenAvg = localData
    .sort((a, b) => b.averageOpenTimeInDays - a.averageOpenTimeInDays)
    .slice(0, 5)
    .map((item) => {
      return {
        // if org_name === NOT FOUND, set to 'Unnamed', otherwise slice to 8 chars and add '...' to end
        name: item.org_name === 'NOT FOUND' ? 'Unnamed' : `${item.org_name.slice(0, 8)}...`,
        pending: item.averageOpenTimeInDays,
        color: item.color,
      };
    });

  return (
    <>
      {chartType === 1 && <StackedBarChart data={topFiveTotalReports} />}
      {chartType === 2 && <HorizontalBarChart1 data={topFiveOpenReports} />}
      {chartType === 3 && <HorizontalBarChart2 data={topFiveLongestOpenAvg} />}
    </>
  );
};

const HorizontalBarChart1 = ({ data }) => {
  return (
    <div style={{ position: 'relative', left: '-20px', top: '8px' }}>
      <BarChart width={560} height={235} data={data} layout="horizontal">
        <XAxis type="category" dataKey="name" />
        <YAxis type="number" />
        <Tooltip />
        <Bar dataKey="open">
          {data.map((d, idx) => (
            <Cell key={`cell-${idx}`} fill={d.color} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

const HorizontalBarChart2 = ({ data }) => {
  return (
    <div style={{ position: 'relative', left: '-20px', top: '8px' }}>
      <BarChart width={560} height={235} data={data} layout="horizontal">
        <XAxis type="category" dataKey="name" />
        <YAxis type="number" />
        <Tooltip />
        <Bar dataKey="pending">
          {data.map((d, idx) => (
            <Cell key={`cell-${idx}`} fill={d.color} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

const StackedBarChart = ({ data }) => {
  return (
    <div style={{ position: 'relative', left: '-20px', top: '8px' }}>
      <BarChart width={560} height={235} data={data} layout="horizontal">
        <XAxis type="category" dataKey="name" />
        <YAxis type="number" />
        <Tooltip />
        <Bar dataKey="open" stackId="a" fill="#45BD5D"></Bar>
        <Bar dataKey="closed" stackId="a" fill="#4585BD"></Bar>
      </BarChart>
    </div>
  );
};

export default TopFiveTablesRepCo;
