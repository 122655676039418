import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import FileItem from './FileItem';
import { SubmitButton, CancelButton } from 'components/Button';

const filesToArray = (files) => Object.keys(files).map((key) => files[key]);

const Upload = React.forwardRef((props, ref) => {
  const {
    accept,
    beforeUpload,
    disabled,
    draggable,
    fileList,
    multiple,
    onChange,
    onFileRemove,
    showList,
    tip,
    uploadLimit,
    children,
    className,
    field,
    form,
    ...rest
  } = props;

  const fileInputField = useRef(null);
  const [files, setFiles] = useState(fileList);
  const [dragOver, setDragOver] = useState(false);

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  const pushFile = (newFiles, file) => {
    for (let f of newFiles) {
      file.push(f);
    }
    return file;
  };

  const addNewFiles = (newFiles) => {
    let file = cloneDeep(files);
    if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
      if (Object.keys(file).length >= uploadLimit) {
        if (uploadLimit === 1) {
          file.shift();
          file = pushFile(newFiles, file);
        }

        return filesToArray({ ...file });
      }
    }
    file = pushFile(newFiles, file);
    return filesToArray({ ...file });
  };

  const onNewFileUpload = (newFiles) => {
    let result = true;

    if (beforeUpload) {
      result = beforeUpload(newFiles, files);

      if (result === false) {
        return;
      }

      if (typeof result === 'string' && result.length > 0) {
        return result;
      }
    }

    if (result) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      onChange?.(updatedFiles, files);
    }
  };

  const removeFile = (fileIndex) => {
    const deletedFileList = files.filter((_, index) => index !== fileIndex);
    setFiles(deletedFileList);
    onFileRemove?.(deletedFileList);
  };

  const triggerUpload = (e) => {
    if (!disabled) {
      fileInputField.current?.click();
    }
    e.stopPropagation();
  };

  const renderChildren = () => {
    if (!draggable && !children) {
      return (
        <SubmitButton disabled={disabled} onClick={(e) => e.preventDefault()}>
          Upload
        </SubmitButton>
      );
    }

    if (draggable && !children) {
      return <span>Choose a file or drag and drop here</span>;
    }

    return children;
  };

  const handleDragLeave = () => {
    if (draggable) {
      setDragOver(false);
    }
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (draggable && !disabled) {
      setDragOver(true);
    }
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (draggable) {
      setDragOver(false);
      onNewFileUpload(e.dataTransfer.files);
    }
  };

  // const draggableProp = {
  //   onDragLeave: handleDragLeave,
  //   onDragOver: handleDragOver,
  //   onDrop: handleDrop,
  // };

  const draggableEventFeedbackClass = `border-indigo-300`;

  const uploadClass = classNames(
    'relative inline-block',
    draggable &&
      'border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg cursor-pointer flex items-center justify-center',
    draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
    draggable && disabled && 'disabled',
    dragOver && draggableEventFeedbackClass,
    className
  );

  const uploadInputClass = classNames('absolute inset-0 hidden', draggable && 'block opacity-0 w-full cursor-pointer');

  return (
    <>
      <div
        ref={ref}
        className={uploadClass}
        draggable={draggable}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={triggerUpload}
        {...rest}
      >
        <input
          className={uploadInputClass}
          type="file"
          ref={fileInputField}
          onChange={(e) => onNewFileUpload(e.target.files)}
          disabled={disabled}
          multiple={multiple}
          accept={accept}
          title=""
          value=""
          {...field}
          {...rest}
        ></input>
        {renderChildren()}
      </div>
      {tip}
      {showList && (
        <div className="mt-4">
          {files.map((file, index) => (
            <FileItem file={file} key={file.name + index}>
              <CancelButton onClick={() => removeFile(index)} className="p-3 mx-2" />
            </FileItem>
          ))}
        </div>
      )}
    </>
  );
});

Upload.propTypes = {
  uploadLimit: PropTypes.number,
  draggable: PropTypes.bool,
  disabled: PropTypes.bool,
  showList: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Upload.defaultProps = {
  draggable: true,
  showList: true,
  disabled: false,
  fileList: [],
};

export default Upload;
