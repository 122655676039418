import axios from 'axios';

export default async function updatePassword(access_token) {
  const params = {
    method: 'PUT',
    url: `${process.env.REACT_APP_API_BASE_URL}/api/update_user_pswd`,
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${access_token}`,
    }
  };
  return axios(params);
}
