import { withAuthenticationRequired } from '@auth0/auth0-react';
import styles from '../styles/Profile.module.css';
import { useContext } from 'react';
import { UserContext } from 'auth/UserContext';
import Loading from 'components/Loading';

const Profile = () => {
  const { userInfo } = useContext(UserContext);

  if (!userInfo || !userInfo.user_metadata) {
    return <Loading />;
  }

  let rolename;
  if (userInfo.user_metadata.role === '1') {
    rolename = 'Somos Admin';
  }
  if (userInfo.user_metadata.role === '2') {
    rolename = 'Resp Org Admin';
  }
  if (userInfo.user_metadata.role === '3') {
    rolename = 'Resp Org User';
  }
  if (userInfo.user_metadata.role === '4') {
    rolename = 'Reporting Entity Admin';
  }
  if (userInfo.user_metadata.role === '5') {
    rolename = 'Reporting Entity User';
  }

  return (
    <div>
      <img className={styles.profileimg} src={userInfo.picture} alt="Profile" />
      <h1 className={styles.profilename}>
        Name: {`${userInfo.user_metadata.first_name} ${userInfo.user_metadata.last_name}`}
      </h1>
      <h1 className={styles.profiletitle}>Created: {userInfo.created_at}</h1>
      <h1 className={styles.profiletitle}>User Role: {rolename}</h1>
    </div>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
