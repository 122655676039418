import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from 'components/Loading';
import { isRepCoAdmin, isRepCoUser, isAdmin, isRespOrgAdmin, isRespOrgUser } from 'util/rolesUtil';
import MainHomePage from './MainHomePage';
import RespOrgHomePage from './RespOrgHomePage';

function Home({ userInfo }) {
  return (
    <>
      {isAdmin(userInfo) && <MainHomePage userInfo={userInfo} />}
      {(isRepCoAdmin(userInfo) || isRepCoUser(userInfo)) && <MainHomePage userInfo={userInfo} />}
      {(isRespOrgAdmin(userInfo) || isRespOrgUser(userInfo)) && <RespOrgHomePage userInfo={userInfo} />}
    </>
  );
}

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <Loading />,
});
