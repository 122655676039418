import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserContext } from 'auth/UserContext';
import { useAuth0 } from '@auth0/auth0-react';
import fetchProfile from 'api/fetchProfile';

const LoggedInMenu = () => {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    if (!userInfo || !userInfo.user_metadata) {
      const getProfile = async () => {
        try {
          const access_token = await getAccessTokenSilently();
          const response = await fetchProfile(user.sub, access_token);
          console.log(response);
          setUserInfo({ ...response, accessToken: access_token });
        } catch (e) {
          console.log(e.message);
        }
      };
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userInfo || !userInfo.user_metadata) {
    return null;
  }

  return (
    <>
      {userInfo.user_metadata.role === '1' ? (
        <ul>
          <li className="header-li">
            <Link to="/home">Home</Link>
          </li>
          <li className="header-li">
            <Link to="/organizations">Organizations</Link>
          </li>
          <li className="header-li">
            <Link to="/reports">Reports</Link>
          </li>
          <li className="header-li">
            <Link to="/analytics">Analytics</Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li className="header-li">
            <Link to="/home">Home</Link>
          </li>
          <li className="header-li">
            <Link to={'/organizations/singleOrg'} state={{ id: userInfo.user_metadata.OrgID }}>
              Organization
            </Link>
          </li>
          <li className="header-li">
            <Link to="/reports">Reports</Link>
          </li>
        </ul>
      )}
    </>
  );
};

export default LoggedInMenu;
