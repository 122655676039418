import axios from 'axios';

export default async function updateProfile(userID, access_token, user_metadata) {
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/update_user_profile`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        user_id: userID,
        user_metadata: user_metadata,
      },
    };
    await axios(params);
  } catch (error) {
    console.log(error.message);
  }
}
