import axios from 'axios';
import { getErrorMessage } from 'components/Message';

export default async function createUser(email, password, access_token, user_metadata) {
  try {
    var params = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/create_user`,
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`, // not used now, for authorization
      },
      data: {
        email: email,
        password: password,
        user_metadata: user_metadata,
      },
    };
    const resp = await axios(params);
    console.log(resp);
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
}
