import React, { useState } from 'react';
import { HiEye, HiTrash } from 'react-icons/hi';
import Upload from 'components/Upload';
import cloneDeep from 'lodash/cloneDeep';
import { Field } from 'formik';
import Dialog from 'components/Dialog';
import ConfirmDialog from 'components/ConfirmDialog';

const UPLOADLIMIT = 4;
const ImageList = (props) => {
  const { fileList, onImageDelete } = props;

  const [selectedImg, setSelectedImg] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const onViewOpen = (img) => {
    setSelectedImg(img);
    setViewOpen(true);
  };

  const onDialogClose = () => {
    setViewOpen(false);
    setTimeout(() => {
      setSelectedImg({});
    }, 300);
  };

  const onDeleteConfirmation = (img) => {
    setSelectedImg(img);
    setDeleteConfirmationOpen(true);
  };

  const onDeleteConfirmationClose = () => {
    setSelectedImg({});
    setDeleteConfirmationOpen(false);
  };

  const onDelete = () => {
    onImageDelete?.(selectedImg);
    setDeleteConfirmationOpen(false);
  };

  const ImgLogo = ({ img }) => {
    if (img.type === 'image/jpeg' || img.type === 'image/png') {
      return <img className="rounded max-h-[140px] max-w-full" src={img.img} alt={img.name} />;
    }

    let path = '../../images/docs.png';

    if (img.type === 'audio/mpeg') {
      path = require('../../images/mp3.png');
    }
    if (img.type === 'audio/wav') {
      path = require('../../images/wav.png');
    }
    if (img.type === 'application/pdf') {
      path = require('../../images/pdf.png');
    }
    if (
      img.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      img.type === 'application/msword'
    ) {
      path = require('../../images/docs.png');
    }

    return (
      <div className="max-h-[140px]">
        {/* <span className="absolute">{img.name}</span> */}
        <img className="rounded  max-w-full bg-cover " src={path} alt={img.name} />
      </div>
    );
  };

  return (
    <>
      {fileList.map((img) => (
        <div className="group relative rounded border p-2 flex" key={img.id}>
          <ImgLogo img={img} />
          <div className="absolute inset-2 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center">
            <span onClick={() => onViewOpen(img)} className="text-gray-100 hover:text-gray-300 cursor-pointer p-1.5">
              <HiEye />
            </span>
            <span
              onClick={() => onDeleteConfirmation(img)}
              className="text-gray-100 hover:text-gray-300 cursor-pointer p-1.5"
            >
              <HiTrash />
            </span>
          </div>
        </div>
      ))}
      <Dialog isOpen={viewOpen} onClose={onDialogClose} onRequestClose={onDialogClose}>
        <h5 className="mb-4">{selectedImg.name}</h5>
        {selectedImg.type === 'image/jpeg' || selectedImg.type === 'image/png' ? (
          <img className="w-full" src={selectedImg.img} alt={selectedImg.name} />
        ) : (
          <embed className="w-full h-full" type={selectedImg.type} src={selectedImg.img} alt={selectedImg.name} />
        )}
      </Dialog>
      <ConfirmDialog
        isOpen={deleteConfirmationOpen}
        onClose={onDeleteConfirmationClose}
        onRequestClose={onDeleteConfirmationClose}
        type="danger"
        title="Remove image"
        onCancel={onDeleteConfirmationClose}
        onConfirm={onDelete}
        confirmButtonColor="red-600"
      >
        <p> Are you sure you want to remove this image? </p>
      </ConfirmDialog>
    </>
  );
};

const FilesUpload = (props) => {
  const { values } = props;
  const beforeUpload = (file) => {
    let valid = true;

    const allowedFileType = [
      'image/jpeg',
      'image/png',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'audio/wav',
      'audio/mpeg',
    ];
    const maxFileSize = 1.049e7;

    for (let f of file) {
      if (!allowedFileType.includes(f.type)) {
        valid = 'Please upload a .jpeg, .png, .doc, .pdf, .wav, and .mp3 file!';
      }

      if (f.size >= maxFileSize) {
        valid = 'Upload image cannot more then 10Mb!';
      }
    }

    return valid;
  };

  const onUpload = (form, field, files) => {
    let imageId = '1-img-0';
    const latestUpload = files.length - 1;

    if (values.fileList.length > 0) {
      const prevImgId = values.fileList[values.fileList.length - 1].id;
      const splitImgId = prevImgId.split('-');
      const newIdNumber = parseInt(splitImgId[splitImgId.length - 1], 10) + 1;
      splitImgId.pop();
      const newIdArr = [...splitImgId, ...[newIdNumber]];
      imageId = newIdArr.join('-');
    }
    const image = {
      id: imageId,
      name: files[latestUpload].name,
      img: URL.createObjectURL(files[latestUpload]),
      type: files[latestUpload].type,
      file: files[latestUpload],
    };
    const fileList = [...values.fileList, ...[image]];
    form.setFieldValue(field.name, fileList);
  };

  const handleImageDelete = (form, field, deletedFile) => {
    let fileList = cloneDeep(values.fileList);
    fileList = fileList.filter((file) => file.id !== deletedFile.id);
    form.setFieldValue(field.name, fileList);
  };

  return (
    <div className="px-8">
      <h5 className="text-xl">Supporting Files</h5>
      <p className="mb-6">Add or change file for the supporting documents</p>
      <Field name="fileList">
        {({ field, form }) => {
          if (values.fileList.length > 0) {
            return (
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                <ImageList fileList={values.fileList} onImageDelete={(img) => handleImageDelete(form, field, img)} />
                {values.fileList.length >= UPLOADLIMIT ? null : (
                  <Upload
                    className="min-h-fit"
                    beforeUpload={beforeUpload}
                    onChange={(files) => onUpload(form, field, files)}
                    showList={false}
                    uploadLimit={UPLOADLIMIT}
                    draggable
                  >
                    <div className="max-w-full flex flex-col px-4 py-2 justify-center items-center">
                      <img src={require('../../images/upload.png')} alt="upload files" />
                      <p className="font-semibold text-center text-gray-800 dark:text-white">Upload</p>
                    </div>
                  </Upload>
                )}
              </div>
            );
          }
          return (
            <Upload
              beforeUpload={beforeUpload}
              onChange={(files) => onUpload(form, field, files)}
              showList={false}
              draggable
              alt="upload files"
            >
              <div className="my-16 p-4 text-center ">
                <img className="mx-auto" src={require('../../images/upload.png')} alt="upload files" />
                <p className="font-semibold">
                  <span className="text-gray-800 dark:text-white">Drop your image here, or </span>
                  <span className="text-blue-500">browse</span>
                </p>
                <p className="mt-1 opacity-60 dark:text-white">Support: jpeg, png, doc, pdf, wav, mp3</p>
              </div>
            </Upload>
          );
        }}
      </Field>
    </div>
  );
};

export default FilesUpload;
